import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';

//MUI
import MUIDataTable from "mui-datatables";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CheckboxWithLabel } from 'formik-mui'
import { DataNumber } from '../FormManager';
import { Card, CardContent, CardHeader, InputAdornment, Typography } from '@mui/material';
const constants = Constants;
const WasteBaselineDetail = props => {
    const { selectedBaseline, selectedDays, DetailObj, ErrObj } = props
    // console.log('--------------------selectedBaseline-------------------------------', selectedBaseline);


    const [msfProduced, setmsfProduced] = React.useState(0);
    const [actualperWaste, setactualperWaste] = React.useState(0);
    const [westReduction, setwestReduction] = React.useState(0);
    const [costReduction, setcostReduction] = React.useState(0);
    const [totalSavings, settotalSavings] = React.useState(0);
    const [HandelKey, setHandelKey] = React.useState('');


    const [reqObj, setreqObj] = React.useState(
        {
            newWaste: DetailObj.newPerWaste,
            totalCount: DetailObj.wasteTotalCount,
            totalWasteSavings: DetailObj.totalWasteSavings
        }
    );
    const [ErObj, setErObj] = React.useState(
        {
            newWasteErr: DetailObj.newPerWaste > 0 ? '' : 'Required',
            totalCountErr: ''
        }
    );

    useEffect(() => {
        let obj = ErObj
        obj.newWasteErr = DetailObj.newPerWaste > 0 ? '' :  ErrObj.newPerWasteErr
        obj.totalCountErr = DetailObj.wasteTotalCount > 0 ? '' : ErrObj.wasteTotalCountErr
        setErObj({ ...obj })
    }, [ErrObj]);
    useEffect(() => {
        if (DetailObj.newPerWaste > 0 && DetailObj.wasteTotalCount > 0) {
            calculation(props.selectedDays)
        }
    }, [props.selectedBaseline]);
    useEffect(() => {
        if (DetailObj.newPerWaste > 0 && DetailObj.wasteTotalCount > 0) {
            calculation(props.selectedDays)
        }
    }, [props.selectedDays]);

    const validate = (values) => {
        const errors = {};
        if (values.newWaste === 0) {
            errors.newWaste = "Must have value";

        }
        if (values.newWaste < 0) {
            errors.newWaste = "invalid input";
        }
        if (values.totalCount === 0) {

            errors.totalCount = "Must have value";
        }

        if (values.totalCount < 0)
            errors.totalCount = "invalid input";

        if (!errors.newWaste && values.newWaste > 0) {
            hendelActualPerWest(values.newWaste)
        }

        if (!errors.totalCount && values.totalCount > 0) {
            handelTotalCount(values.totalCount)
        }
        if (errors.newWaste !== "" && HandelKey === 'newWaste') {
            setError('newWasteErr', '')
        }
        if (errors.totalCount !== "" && HandelKey === 'totalCount') {
            setError('totalCountErr', '')
        }
        props.updateRequest(values.newWaste, 'newPerWaste')
        props.updateRequest(values.totalCount, 'wasteTotalCount')
        return errors;
    }
    const setError = (key, value) => {
        let obj = ErObj
        obj[key] = value
        setErObj({ ...obj })
        // setErObj(ErObj => ({  ...ErObj,  [key]: value }));
    }
    const hendelActualPerWest = (e) => {
        let data = reqObj
        data.newWaste = e
        setreqObj({ ...data })

        calculation(props.selectedDays, 'newWaste') 
    }
    const handelTotalCount = (e) => {
        let data = reqObj
        data.totalCount = e
        setreqObj({ ...data })
        calculation(props.selectedDays, 'totalCount') 
    }

    const calculation = (selectedRadio, key) => {
        // console.log('check reqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObjreqObj', reqObj);
        if (reqObj.newWaste > 0 && reqObj.totalCount > 0) {
            let msfProduced = selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)
            let actualperWaste = selectedBaseline.actualPrcWaste
            let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
            let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
            let westReduction = actualperWaste - reqObj.newWaste
            let savings = 0

            if (selectedRadio === 'day') {
                let costReduction = westReduction / 100 * msfProduced * costMSFWperPP
                let totalSavings = costReduction * reqObj.totalCount
                setmsfProduced(msfProduced)
                setactualperWaste(actualperWaste)
                setwestReduction(westReduction)
                setcostReduction(costReduction)
                settotalSavings(totalSavings)
                savings = totalSavings
            } else if (selectedRadio === 'week') {
                let msfProducedWeek = msfProduced * selectedBaseline.daysPerWeek
                let actualperWasteWeek = reqObj.newWaste
                let westReductionWeek = actualperWaste - actualperWasteWeek
                let costReductionWeek = westReductionWeek / 100 * msfProducedWeek * costMSFWperPP
                let totalSavingsWeek = costReductionWeek * reqObj.totalCount
                setmsfProduced(msfProducedWeek)
                setactualperWaste(actualperWasteWeek)
                setwestReduction(westReductionWeek)
                setcostReduction(costReductionWeek)
                settotalSavings(totalSavingsWeek)

                savings = totalSavingsWeek
            } else if (selectedRadio === 'month') {
                let msfProducedMonth = ((selectedBaseline.daysPerWeek * 4.25) * msfProduced)
                let actualperWasteMonth = reqObj.newWaste
                let westReductionMonth = actualperWaste - actualperWasteMonth
                let costReductionMonth = westReductionMonth / 100 * msfProducedMonth * costMSFWperPP
                let totalSavingsMonth = costReductionMonth * reqObj.totalCount
                setmsfProduced(msfProducedMonth)
                setactualperWaste(actualperWasteMonth)
                setwestReduction(westReductionMonth)
                setcostReduction(costReductionMonth)
                settotalSavings(totalSavingsMonth)
                savings = totalSavingsMonth
            }
            props.wasteHandle({...reqObj, totalWasteSavings: savings}, key)
        }
    }
    // ((5/100*(78.26*2.5))+((1-5/100)*78.26))*1
    const formatnum = (num) => {
        let value = parseFloat(num)
        let formatted = Math.round(value);
        return parseFloat(formatted).toLocaleString();
    }
    const Tformatnum = (number) => {
      if (isNaN(number) || number === null) {
        return "Invalid Number";
    }
    if (Number.isInteger(number)) {
        return number.toString();
    } else {
        let formattedNumber = Number(number).toFixed(2);
        formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNumber;
    }
    }
    const handleFocus = (e, keyName) => {
        // console.log(keyName);
        setHandelKey(keyName)
        e.target.select(); // Selects the input value
    };
// console.log("selectedBaseline", selectedBaseline);
    if (selectedBaseline !== null) {
        return <>
            <Grid container xl={6} style={{ padding: '16px', marginTop: '0px' }}>
                <Grid item xl={12}>
                    <Formik initialValues={reqObj} validate={validate}>
                        <Form>
                            <Card variant="outlined" >
                                <CardHeader title="Waste" />
                                <CardContent>
                                    <Grid container style={{ paddingLeft: '20px' }}>
                                        <Grid container spacing={3} sx={{ alignItems: 'center', display: 'flex', textAlignLast: 'center', borderBottom: 1, borderTop: 1, borderLeft: 1 }} item xs={6}>
                                            <Grid item xs={12}>
                                                <p style={{fontSize: '12px', margin: '0px', marginBottom: '2px', paddingLeft: '7rem'}}>Baseline Value: {selectedBaseline.actualPrcWaste.toFixed(2)}%</p>
                                                <Field id='newWaste' inputMode="numeric" pattern="[0-9]*" style={{}} onWheel={(e) => e.target.blur()} label="New %Waste" component={TextField} name="newWaste" type="number" 
                                                    onFocus={(e) => handleFocus(e, "newWaste")} inputProps={{ min: 0, step: "any", onKeyDown: (e) => { 
                                                            if (e.keyCode === 13) {
                                                                e.preventDefault();
                                                            }
                                                            if (e.keyCode === 38 && e.target.value <= 0 || e.keyCode === 189) {
                                                                e.preventDefault();
                                                            }
                                                        }}} />
                                                {
                                                    ErObj.newWasteErr !== "" ? <Typography style={{ fontSize: '0.75rem' }} color="error">{ErObj.newWasteErr}</Typography> : <></>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} spacing={3} sx={{ borderTop: 1 }}>
                                            <Grid sx={{ borderLeft: 1, borderBottom: 1, borderRight: 1 }} item xs={6}>
                                                <div>Msf Produced</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <div>
                                                    {/* {(selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift) * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)} */}
                                                    {formatnum(msfProduced)}
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderLeft: 1, borderBottom: 1, borderRight: 1 }} item xs={6}>
                                                <div>Actual % Waste</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} >

                                                <div align="center">
                                                    {actualperWaste.toFixed(2)} %
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderLeft: 1, borderBottom: 1, borderRight: 1 }} item xs={6}>
                                                <div>Waste Reduction</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1 }} item xs={6} align="center">
                                                {
                                                    reqObj.newWaste > 0 ? <div>{westReduction.toFixed(2)} %</div> : <div>{actualperWaste.toFixed(2)} %</div>
                                                }
                                            </Grid>
                                            <Grid sx={{ borderLeft: 1, borderBottom: 1, borderRight: 1 }} item xs={6}>
                                                <div>Cost Reduction</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{margin: '0px'}}>$</p>
                                                    <p style={{margin: '0px'}}>
                                                        {
                                                            formatnum(Math.round(costReduction))
                                                        }
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid sx={{ borderLeft: 1, borderBottom: 1, borderRight: 1 }} item xs={6}>
                                                <div>Total {props.selectedDays.charAt(0).toUpperCase() + props.selectedDays.slice(1)}</div>
                                            </Grid>
                                            <Grid sx={{ borderBottom: 1, borderRight: 1, paddingRight: 1 }} item xs={6} align="right">
                                                {Tformatnum(reqObj.totalCount)}
                                                {/* <Field 
                                                inputProps={{ readOnly: true }}
                                                onWheel={(e) => e.target.blur()}
                                                style={{}} 
                                                label={'Total ' + props.selectedDays.charAt(0).toUpperCase() + props.selectedDays.slice(1)} component={TextField} name="totalCount" type="number"
                                                    />
                                                {
                                                    ErObj.totalCountErr !== "" ? <Typography style={{ fontSize: '0.75rem' }} color="error">{ErObj.totalCountErr}</Typography> : <></>
                                                } */}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container item xs={12} sx={{ marginTop: 2, fontWeight: 'bold', alignItems: 'center' }}>
                                                <Grid item xs={6}>
                                                    Total Waste Saving
                                                </Grid>
                                                <Grid container item xs={6} align="right" sx={{ paddingRight: 1 }}>
                                                    <Grid item xs={6}>

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <p>$</p>
                                                            <p>{Tformatnum(totalSavings)}</p>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    </Formik>
                </Grid>
            </Grid>
        </>
    }
};
export default WasteBaselineDetail;