import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import produce from "immer";
import { StepToLabel } from '../components/Formula/StepTypeViews';
import { GetRequest } from './RequestActions';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { log } from 'logrocket';
export const AddIngredientVerification = (newIngVer) => {  
    console.log('add ingredient verification');
    return {
        type: Constants.Actions.IngredientVerification_Add,
        payload: newIngVer,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.IngredientVerification, method: 'POST', body: JSON.stringify(newIngVer), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.IngredientVerification_Add_Commit, meta: { newIngVer }
                },
                rollback: {
                    type: Constants.Actions.IngredientVerification_Add_Rollback, meta: { newIngVer }
                }
            }
        }
    }
};
export const UpdateIngredientVerification = (updIngVer,curIngVer) => {
    console.log('put check __________________________________________________________________________  99');
    const data = { update: updIngVer, current: curIngVer }
    return {
        type: Constants.Actions.IngredientVerification_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.IngredientVerification + updIngVer.id, method: 'PUT', body: JSON.stringify(updIngVer), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.IngredientVerification_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.IngredientVerification_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const AddIngredientVerificationStep = (newIVStep) => {
    console.log('add ingredient verification step');
    return {
        type: Constants.Actions.IngredientVerificationStep_Add,
        payload: newIVStep,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.IngredientVerificationStep, method: 'POST', body: JSON.stringify(newIVStep), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.IngredientVerificationStep_Add_Commit, meta: { newIVStep }
                },
                rollback: {
                    type: Constants.Actions.IngredientVerificationStep_Add_Rollback, meta: { newIVStep }
                }
            }
        }
    }
};
export const UpdateIngredientVerificationStep = (updIngVerStep, curIngVerStep) => {
    console.log('updated ingstep----')
    const data = { update: updIngVerStep, current: curIngVerStep }
    return {
        type: Constants.Actions.IngredientVerificationStep_Update,
        payload: data,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.IngredientVerificationStep + updIngVerStep.id, method: 'PUT', body: JSON.stringify(updIngVerStep), headers: { 'content-type': 'application/json' }
                },
                commit: {
                    type: Constants.Actions.IngredientVerificationStep_Update_Commit, meta: { data }
                },
                rollback: {
                    type: Constants.Actions.IngredientVerificationStep_Update_Rollback, meta: { data }
                }
            }
        }
    }
};
export const SetIVFormulaID = (formulaID, IngVer) => {
    return (dispatch, getState) => {
        //Get Formula and FormulaSteps
        const d = new Date();
        const state = getState();
        const formula = state.Formulas.find(f => f.id == formulaID);
        const fSteps = state.FormulaSteps.filter(fs => fs.formulaID == formulaID && !fs.deleted);
        const updIV = produce(IngVer, iv => {
            iv.formulaID = formulaID;
            iv.formulaName = formula.name;
            iv.startWeight = 0;
            iv.name = formula.name +' - '+ d.toLocaleDateString();
        });
        dispatch(UpdateIngredientVerification(updIV,IngVer));
        fSteps.forEach(step => {
            const typeKey = Object.keys(Constants.Enums.StepType).find(key => Constants.Enums.StepType[key] == step.stepType);
            let stepDesc = StepToLabel(typeKey);
            if (step.product) {
                stepDesc = stepDesc + "-" + step.product
            }
            const newIVStep = {
                id: uuidv4(),
                starchIngredientVerificationID: updIV.id,
                stepType: step.stepType,
                stepLocation: step.stepNumber,
                product: step.product,
                step: stepDesc,
                value: step.value,
                unit: step.unit,
                mixTime: step.mixTime,
                cost: step.cost,
                accumulatedWeight: 0,
                mixerWeight: 0,
                acutalWeight: 0,
                temperature: 0,
                lbsPerGallon: step.lbsPerGallon,
                dryPercentage:step.dryPercentage
            };
            dispatch(AddIngredientVerificationStep(newIVStep));
        });
    }
};
export const DeleteIngredientVerificationByID = delIngVerID =>{
    return (dispatch,getState)=>{
        const delIngVer=getState().IngredientVerifications.find(iv=>iv.id===delIngVerID);
        dispatch(DeleteIngredientVerification(delIngVer));
    }
};
export const DeleteIngredientVerification = (delIngVer) => {
    return {
        type: Constants.Actions.IngredientVerification_Delete,
        payload: delIngVer,
        meta: {
            offline: {
                effect: {
                    url: Constants.URLs.IngredientVerification + delIngVer.id, method: 'DELETE'
                },
                commit: {
                    type: Constants.Actions.IngredientVerification_Delete_Commit, meta: { delIngVer }
                },
                rollback: {
                    type: Constants.Actions.IngredientVerification_Delete_Rollback, meta: { delIngVer }
                }
            }
        }
    }
};
export const ClearIVData = () => { 
    return (dispatch,gs) => { 
        dispatch({type: Constants.Actions.IngredientVerification_ClearData });
        dispatch({type:Constants.Actions.IngredientVerificationStep_ClearData});
    }; 
};
export const CalculateAccumulatedWeight = (startWeight, ingredientVerificationID) => {
    return {
        type: Constants.Actions.IngredientVerificationStep_ReCalc_AccumulatedWeight,
        startWeight: startWeight,
        ingredientVerificationID: ingredientVerificationID
    }
}
export const GetIngredientVerificationStepsByParentID = (parentID) => {
    console.log('get ingredient verification steps by parent id');
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.IngredientVerificationStep + "?IngredientVerificationID=" + parentID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetIngredientVerificationStepsByParentID", parentID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: Constants.Actions.IngredientVerificationStep_GetByParentID, data: ivs, parentID: parentID });
                        
                    }
                })
                .catch(FetchCatchHandler("GetIngredientVerificationStepsByParentID", parentID, dispatch))
        }
    }
}
export const GetIngredientVerificationByVisitID = (visitID) => {
    console.log('get ingredient verification by visit id');
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online) {
            let fetchTask = fetch(Constants.URLs.IngredientVerification + "?visitID=" + visitID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetIngredientVerificationByVisitID", visitID, dispatch))
                .then(response => response.json())
                .then(ivs => {
                    //Ignore 404 errors
                    if (Array.isArray(ivs)) {
                        dispatch({ type: Constants.Actions.IngredientVerification_GetByVisit, data: ivs, visitID:visitID });
                        ivs.forEach(iv => {
                            dispatch(GetIngredientVerificationStepsByParentID(iv.id));
                        });
                    }
                })
                .catch(FetchCatchHandler("GetIngredientVerificationByVisitID", visitID, dispatch))
        }
    }
}
export const GetIngredientVerification = (ivID) => {
    return (dispatch, getState) => {
        dispatch(GetRequest(Constants.URLs.IngredientVerification + ivID, 'GetIngredientVerification', ivID
            , (data) => {
                dispatch({ type: Constants.Actions.IngredientVerification_Get, data: data });
            })
        );
    }
}