import Constants from '../../constants';
//Modules
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams,useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
//MUI
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
//Actions
import { GetPlantFormulas } from "../../actions/FormulaActions";
import { AddIngredientVerification, GetIngredientVerificationByVisitID } from "../../actions/IngredientVerificationActions";
import { AddNewHeatVessel, GetHeatVesselsByVisitID } from '../../actions/HeatVesselActions';
import { AddNewGlueRoll, GetGlueRollsByVisitID } from '../../actions/GlueRollActions';
import { AddNewGlueLine, GetGlueLinesByVisitID } from '../../actions/GlueLineActions';
import { CreateBHSChecklist, GetChecklistsByVisitID } from '../../actions/ChecklistActions';
import { AddNewThermal } from "../../actions/ThermalImageActions";
import { AddNewContiImprovement } from "../../actions/ContinuousImprovementAction";
import { GetKeyItemByVisitID } from "../../actions/KeyItemActions";

//Misc

export const VisitAddButton = ({visit}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const handleAdd = CreateHandleAdd(dispatch, visit,navigate);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const itemClicked = (addType) => {
        const clickType = addType;
        return () => {
            handleClose();
            handleAdd(clickType);
        };
    }
    return (
        <>
            <Fab color="primary" aria-label="add" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <AddIcon />
            </Fab>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={itemClicked('Glue Line')}>Glue Line</MenuItem>
                <MenuItem onClick={itemClicked('Glue Roll Measurement')}>Glue Roll Measurement</MenuItem>
                <MenuItem onClick={itemClicked('Glue Roll Pictures')}>Glue Roll Pictures</MenuItem>
                <MenuItem onClick={itemClicked('Heat Vessel')}>Heat Vessel</MenuItem>
                <MenuItem onClick={itemClicked('Ingredent Verification')}>Ingredent Verification</MenuItem>
                <MenuItem onClick={itemClicked('BHS WCS Checklist')}>BHS WCS Checklist</MenuItem>
                <MenuItem onClick={itemClicked('Thermal Images')}>Thermal Images</MenuItem>
                <MenuItem onClick={itemClicked('Continuous Improvement')}>Continuous Improvement</MenuItem>
                <MenuItem onClick={itemClicked('Starch Kitchen')}>Starch Kitchen</MenuItem>
                <MenuItem onClick={itemClicked('Starch Checks')}>Starch Checks</MenuItem>
                
            </Menu>
        </>
    );
};
export const VisitCloseButton = props => {
    return <Fab  aria-label="close" onClick={props.closeVisit}>
        <CloseIcon />
    </Fab>
}
export const CreateHandleAdd = (dispatch,visit,navigate) => {
    return addType => {
        switch (addType) {
            case 'Ingredent Verification':
                const newIngVer = {};
                newIngVer.id = uuidv4();
                newIngVer.visitID = visit.id;
                dispatch(AddIngredientVerification(newIngVer));
                navigate('/IngredientVerification/' + newIngVer.id);
                break;
            case 'Heat Vessel':
                const newHV = {};
                newHV.id = uuidv4();
                newHV.visitID = visit.id;
                newHV.date = new Date().toJSON();
                dispatch(AddNewHeatVessel(newHV));
                navigate('/HeatVessel/' + newHV.id);
                break;
            case 'Glue Roll Measurement':
                const newGR = {};
                newGR.id = uuidv4();
                newGR.visitID = visit.id;
                newGR.date = new Date().toJSON();
                newGR.glueRollType=0;
                dispatch(AddNewGlueRoll(newGR));
                navigate('/GlueRoll/' + newGR.id);
                break;
            case 'Glue Roll Pictures':
                const newGRPic = {};
                newGRPic.id = uuidv4();
                newGRPic.visitID = visit.id;
                newGRPic.date = new Date().toJSON();
                newGRPic.glueRollType=1;
                dispatch(AddNewGlueRoll(newGRPic));
                navigate('/GlueRoll/' + newGRPic.id);
                break;
            case 'Glue Line':
                const newGL = {};
                newGL.id = uuidv4();
                newGL.visitID = visit.id;
                newGL.date = new Date().toJSON();
                dispatch(AddNewGlueLine(newGL));
                navigate('/GlueLine/' + newGL.id);
                break;
           
            case 'BHS WCS Checklist':
                const bhsID = uuidv4()
                dispatch(CreateBHSChecklist(bhsID, visit.id));
                navigate('/Checklist/' + bhsID);
                break;
            case 'Thermal Images':
                const tiID = uuidv4()
                dispatch(AddNewThermal(tiID, visit.id));
                navigate('/ThermalImages/' + tiID);
                break;
            case 'Continuous Improvement':
                const ciID = uuidv4()
                // dispatch(AddNewContiImprovement(ciID, visit.id));
                navigate('/ContinuousImprovement/' + visit.id + '/' + 0);
                break;
            case 'Starch Kitchen':
                const skID = uuidv4()
                navigate('/StartchKitchen/' + visit.id + '/' + 'add');
                break;
            case 'Starch Checks':
                const sChID = uuidv4()
                navigate('/StartchChecks/' + visit.id + '/' + 'add');
                break;
            default:
                alert(addType + " not upgraded yet.");
        }
    };
}