import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
//MUI
import { selectKeyItemsByVisitID, selectMultiKeyItems } from '../../selectors/KeyItemSelectors';
import { GetAttachmentsByArray, GetAttachment, DeleteAttachment } from '../../actions/AttachmentActions';
import { GetGLMachinesByGLID } from '../../selectors/GlueLineSelectors';
import { SelChecklistByID, SelChecklistGroupsByCLID, SelChecklistItemsByCLID } from '../../selectors/ChecklistSelectors';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
//Components
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { PlantLink } from '../MetaData/Links';
import { DateView } from '../MetaData/MiscViews';
import { VisitAddButton, VisitCloseButton } from './VisitCommon';
//Actions
import { UpdateAction } from '../../actions/DataActions';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));
export const VisitHeader = props => {
    const queryString = window.location.search;
    let Printoption = ''
    if (queryString !== '') {
        Printoption = queryString.substring(1)
    }
    const params = new URLSearchParams(queryString);    
    const [modalOpen, setModal] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();
    const closeVisit = () => navigate(-1);
    const handlePrint = () => {
        localStorage.removeItem('IdList');
        setModal(true);
    }

    useEffect(() => {
        localStorage.removeItem('IdList');
    }, [modalOpen]);
    useEffect(() => {        
        if (Printoption === 'printOption') {
            handlePrint()
    const { pathname } = window.location;
    window.history.replaceState(null, '', pathname);
        }
    }, [Printoption]);
    const handleEmail = () => navigate('/VisitPDFs/Email/' + props.visit.id)
    return (<>
        <Paper variant="outlined" className={classes.heading}>
            <Grid container spacing={3} alignItems="center"
                justifyContent="center">
                <Grid item xs={4} md={2}>
                    <PlantLink plantID={props.visit.plantID} />
                </Grid>
                <Grid item xs={4} md={2}>
                    Start: <DateView value={props.visit.startDate} />
                </Grid>
                <Grid item xs={4} md={2}>
                    End: <DateView value={props.visit.endDate} />
                </Grid>
                <Grid item xs={4} md={2}>
                    Type: {props.visit.type}
                </Grid>
                <Grid item xs={2} md={1}>
                    <Button onClick={handleEmail}>Email</Button>
                </Grid>
                <Grid item xs={2} md={1}>
                    <Button onClick={handlePrint}>Print</Button>
                </Grid>
                <Grid item xs={2} md={1}>
                    <VisitAddButton visit={props.visit} />
                </Grid>
                <Grid item xs={2} md={1}>
                    <VisitCloseButton closeVisit={closeVisit} />
                </Grid>
            </Grid>
        </Paper>
        <ReportItemsDialog plantID={props.visit.plantID} VisitId={props.visit.id} modalOpen={modalOpen} closeModal={() => setModal(false)} />
    </>
    );
}

export const ReportItemsDialog = props => {
    const state = useSelector(s => s);
    const navigate = useNavigate();
    const [IsSelectAll, setIsSelectAll] = useState(false);
    const [IsKeyItem, setIsKeyItem] = useState(false);
    const [IsActionItems, setActionItemsData] = useState(false);
    const [IsAllSurvey, setIsAllSurvey] = useState(false);
    const [IsSelectGluLine, setIsSelectGluLine] = useState(false);
    const [IsInventory, setIsInventory] = useState(false);
    const [IsFormulas, setIsFormulas] = useState(false);
    const [IsStarchGraph, setIsStarchGraph] = useState(false);
    const [IsGluRolM, setIsGluRolM] = useState(false);
    const [IsGluRolP, setIsGluRolP] = useState(false);
    const [IsHeatVe, setIsHeatVe] = useState(false);
    const [IsIngredent, setIsIngredent] = useState(false);
    const [IsBhsList, setIsBhsList] = useState(false);
    const [IsThermalImg, setIsThermalImg] = useState(false);
    const [IsCiList, setIsCiList] = useState(false);
    const [IsStartchKi, setIsStartchKi] = useState(false);
    const [IsStartchCheck, setIsStartchCheck] = useState(false);
    const [ItemError, setItemError] = useState(false);
    const [IdList, setIdList] = useState([]);
    const [IsKey_ItemCount, setIsKey_ItemCount] =  useState(null)
    const [IsAction_ItemCount, setIsAction_ItemCount] =  useState(null)
    const [IsInventoryCount, setIsInventoryCount] =  useState(null)
    const [IsSelectGlu_LineCount, setIsSelectGlu_LineCount] =  useState(null)
    const [IsGlu_RolPCount, setIsGlu_RolPCount] =  useState(null)
    const [IsBhs_ListCount, setIsBhs_ListCount] =  useState(null)
    const [IsTherma_lImgCount, setIsTherma_lImgCount] =  useState(null)
    const [Formula_ImgCount, setFormula_ImgCount] =  useState(null)
    const [unitType, setUnitType] = useState('imperial');
    const [isMetric, setIsMetric] = useState(false);
    const [Loader, setLoader] =  useState(false)

    const handleUnitChange = (event) => {
        const selectedUnitType = event.target.value;
        setUnitType(selectedUnitType);
        setIsMetric(selectedUnitType === 'metric'); // Set isMetric based on selection
        console.log('Current unit type:', selectedUnitType);
    };

    useEffect(() => {
        if (IsSelectGluLine && IsGluRolM && IsGluRolP && IsHeatVe && IsIngredent && IsBhsList && IsThermalImg && IsCiList && IsStartchKi && IsStartchCheck) {
            setIsAllSurvey(true)

            if (IsKeyItem) {
                setIsSelectAll(true) 
            }
        } else {
            setIsAllSurvey(false)
        }
    }, [IsSelectGluLine, IsGluRolM, IsGluRolP, IsHeatVe, IsIngredent, IsBhsList, IsThermalImg, IsCiList, IsStartchKi, IsStartchCheck]);
    const handleCheckAll = (event) => {
        setItemError(false)
        if (event.target.checked) {
            setIsSelectAll(true)
            checkAll(true)
        } else {
            setIsSelectAll(false)
            checkAll(false)
        }
    };
    const handleCheckItems = (event) => {
        setItemError(false)
        if (event.target.checked) {
            setIsKeyItem(true)
            if (IsAllSurvey) {
                setIsSelectAll(true)
            }
        } else {
            setIsKeyItem(false) 
            setIsSelectAll(false)
        }
    };
    const handleActionItems = (event) => {
        setItemError(false)
        if (event.target.checked) {
            setActionItemsData(true)
            if (IsAllSurvey) {
                setIsSelectAll(true)
            }
        } else {
            setActionItemsData(false) 
            setIsSelectAll(false)
        }
    };
    const handleCheckSurvey = (event) => {
        setItemError(false)
        if (event.target.checked) {
            setIsAllSurvey(true)
            if (IsKeyItem) {
                setIsSelectAll(true)
            }
            checkSurveyItems(true)
        } else {
            checkSurveyItems(false)
            setIsAllSurvey(false) 
            setIsSelectAll(false)
        }
    };
    const handleSItemCheck = (value, from) => {
        setItemError(false)
        switch (from) {
            case 'IsSelectGluLine'://Rollstands 
                if (value) {
                    setIsSelectGluLine(true)
                } else {
                    setIsSelectGluLine(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsFormulas'://setIsFormulas
                if (value) {
                    setIsFormulas(true)
                } else {
                    setIsFormulas(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsInventory'://setIsFormulas
                if (value) {
                    setIsInventory(true)
                } else {
                    setIsInventory(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsStarchGraph'://IsStarchGraph
                if (value) {
                    setIsStarchGraph(true)
                } else {
                    setIsStarchGraph(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsGluRolM'://Rollstands 
                if (value) {
                    setIsGluRolM(true)
                } else {
                    setIsGluRolM(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsGluRolP': //IsGluRolP
                if (value) {
                    setIsGluRolP(true)
                } else {
                    setIsGluRolP(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsHeatVe': //IsHeatVe
                if (value) {
                    setIsHeatVe(true)
                } else {
                    setIsHeatVe(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsIngredent': //IsIngredent
                if (value) {
                    setIsIngredent(true)
                } else {
                    setIsIngredent(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsBhsList': //IsBhsList
                if (value) {
                    setIsBhsList(true)
                } else {
                    setIsBhsList(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsThermalImg': //IsThermalImg
                if (value) {
                    setIsThermalImg(true)
                } else {
                    setIsThermalImg(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsCiList': //IsCiList
                if (value) {
                    setIsCiList(true)
                } else {
                    setIsCiList(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsStartchKi': //IsStartchKi
                if (value) {
                    setIsStartchKi(true)
                } else {
                    setIsStartchKi(false)
                    setIsSelectAll(false)
                }
            break;
            case 'IsStartchCheck': //IsStartchCheck
                if (value) {
                    setIsStartchCheck(true)
                } else {
                    setIsStartchCheck(false)
                    setIsSelectAll(false)
                }
            break;
            default:
                return; //Ignore 
            }
    };
    function checkAll(value) {
        setItemError(false)
        if (value) {
            setIsKeyItem(true) 
            setActionItemsData(true) 
            setIsAllSurvey(true)
            setIsSelectGluLine(true)
            setIsFormulas(true)
            setIsInventory(true)
            setIsStarchGraph(true)
            setIsGluRolM(true)
            setIsGluRolP(true)
            setIsHeatVe(true)
            setIsIngredent(true)
            setIsBhsList(true)
            setIsThermalImg(true)
            setIsCiList(true)
            setIsStartchKi(true)
            setIsStartchCheck(true)
        } else {
            setIsKeyItem(false) 
            setActionItemsData(false) 
            setIsAllSurvey(false)
            setIsSelectGluLine(false)
            setIsFormulas(false)
            setIsInventory(false)
            setIsStarchGraph(false)
            setIsGluRolM(false)
            setIsGluRolP(false)
            setIsHeatVe(false)
            setIsIngredent(false)
            setIsBhsList(false)
            setIsThermalImg(false)
            setIsCiList(false)
            setIsStartchKi(false)
            setIsStartchCheck(false)
        }
    };
    function checkSurveyItems(value) {
        setItemError(false)
        if (value) {
            setIsSelectGluLine(true)
            setIsFormulas(true)
            setIsInventory(true)
            setIsStarchGraph(true)
            setIsGluRolM(true)
            setIsGluRolP(true)
            setIsHeatVe(true)
            setIsIngredent(true)
            setIsBhsList(true)
            setIsThermalImg(true)
            setIsCiList(true)
            setIsStartchKi(true)
            setIsStartchCheck(true)
        } else {
            setIsSelectGluLine(false)
            setIsFormulas(false)
            setIsInventory(false)
            setIsStarchGraph(false)
            setIsGluRolM(false)
            setIsGluRolP(false)
            setIsHeatVe(false)
            setIsIngredent(false)
            setIsBhsList(false)
            setIsThermalImg(false)
            setIsCiList(false)
            setIsStartchKi(false)
            setIsStartchCheck(false)
        }
    };
    const gotrdtPrint =()=>{
        if (!IsKeyItem && !IsActionItems && !IsStarchGraph && !IsFormulas && !IsInventory && !IsSelectGluLine && !IsGluRolM && !IsGluRolP && !IsHeatVe && !IsIngredent && !IsBhsList && !IsThermalImg && !IsCiList && !IsStartchKi && !IsStartchCheck) {
            setItemError(true)
        } else {
            setLoader(true)
            if (IsKeyItem) { 
                let value = getImageCount('IsKeyItem')
                if ( value !== null) {
                    setIsKey_ItemCount(value)    
                }
            } else {
                setIsKey_ItemCount(0)    
            }
            if (IsActionItems) { 
                let value = getImageCount('IsActionItem')
                if ( value !== null) {
                    setIsAction_ItemCount(value)    
                }
            } else {
                setIsAction_ItemCount(0)    
            }
            if (IsSelectGluLine) { 
                let value = getImageCount('IsSelectGluLine')
                if ( value !== null) {
                    setIsSelectGlu_LineCount(value)    
                }
            } else {
                setIsSelectGlu_LineCount(0)
            }
            if (IsGluRolP) { 
                let value = getImageCount('IsGluRolP')
                if ( value !== null) {
                    setIsGlu_RolPCount(value)    
                }
            } else {
                setIsGlu_RolPCount(0)
            }
            if (IsBhsList) { 
                let value = getImageCount('IsBhsList')
                if ( value !== null) {
                    setIsBhs_ListCount(value)    
                }
            } else {
                setIsBhs_ListCount(0)
            }
            if (IsThermalImg) { 
                let value = getImageCount('IsThermalImg')
                if ( value !== null) {
                    setIsTherma_lImgCount(value)    
                }
            } else {
                setIsTherma_lImgCount(0)
            }
            if (IsFormulas) { 
                let value = getImageCount('IsFormulas')
                if ( value !== null) {
                    setFormula_ImgCount(value)    
                }
            } else {
                setFormula_ImgCount(0)
            }
            if (IsInventory) { 
                let value = getImageCount('IsInventory')
                if ( value !== null) {
                    setIsInventoryCount(value)    
                }
            } else {
                setIsInventoryCount(0)
            }
        }        
    }
    useEffect(() => {
        if (IsKey_ItemCount !== null && IsAction_ItemCount !== null && IsSelectGlu_LineCount !== null && IsGlu_RolPCount !== null && IsBhs_ListCount !== null && IsInventoryCount !== null && IsTherma_lImgCount !== null) {
            let count = IsKey_ItemCount + IsSelectGlu_LineCount + IsGlu_RolPCount + IsBhs_ListCount + IsTherma_lImgCount
            localStorage.setItem('IdList', JSON.stringify(IdList));
            let jsonData = {
                IsKeyItem: IsKeyItem, 
                IsActionItems: IsActionItems,
                IsSelectGluLine: IsSelectGluLine, 
                IsGluRolM: IsGluRolM, 
                IsGluRolP: IsGluRolP, 
                IsHeatVe: IsHeatVe, 
                IsIngredent: IsIngredent, 
                IsBhsList: IsBhsList, 
                IsThermalImg: IsThermalImg, 
                IsCiList: IsCiList, 
                IsStartchKi: IsStartchKi, 
                IsStartchCheck: IsStartchCheck, 
                IsFormulas: IsFormulas, 
                IsInventory: IsInventory, 
                IsStarchGraph: IsStarchGraph, 
            }
            console.log('jsonDatajsonDatajsonDatajsonData', jsonData);
            
            const queryString = new URLSearchParams({ data: JSON.stringify(jsonData), count: count, unitType: unitType }).toString();
            navigate(`/VisitAllReport/${props.VisitId}?${queryString} `)
        }
     }, [ IsKey_ItemCount, IsAction_ItemCount, IsSelectGlu_LineCount , IsGlu_RolPCount , IsBhs_ListCount , IsTherma_lImgCount, IsInventoryCount   ]);

    function pushIdInList(id) {
        if (id) {
            setIdList(prevIdList => {
                if (!prevIdList.includes(id)) {
                    return [...prevIdList, id];
                }
                return prevIdList;
            });
        }
    }
    function getImageCount(from) {
       if (from === 'IsKeyItem') {
            const keyItems = selectKeyItemsByVisitID(state, props.VisitId);
        let value = 0
        keyItems.map((i) => { 
            if (i.imageID !== '00000000-0000-0000-0000-000000000000' && i.imageID !== '') {
                pushIdInList(i.imageID)
                value += 1
            }
        });        
        return value
       }
       if (from === 'IsSelectGluLine') {
        const glueLine = state.GlueLines.filter(i => i.visitID == props.VisitId && !i.deleted)
        let value = 0
        glueLine.forEach(gl => {
            let maschins = GetGLMachinesByGLID(state, gl.id)
            maschins.forEach(m => {
                if (m.imageID !== '00000000-0000-0000-0000-000000000000'  && m.imageID !== '') {
                    pushIdInList(m.imageID)
                    value += 1
                }
                if (m.secondImageID !== '00000000-0000-0000-0000-000000000000'  && m.secondImageID !== '') {
                    pushIdInList(m.secondImageID)
                    value += 1
                }
                if (m.sfImageID !== '00000000-0000-0000-0000-000000000000'  && m.sfImageID !== '') {
                    pushIdInList(m.sfImageID)
                    value += 1
                }
                if (m.sfSecondImageID !== '00000000-0000-0000-0000-000000000000'  && m.sfSecondImageID !== '') {
                    pushIdInList(m.sfSecondImageID)
                    value += 1
                }
            })
        });
        return value
       }
       if (from === 'IsGluRolP') {
        const glueRoles = state.GlueRolls.filter(gr => gr.visitID == props.VisitId && !gr.deleted)
        let value = 0
        glueRoles.forEach(gr => {
            let grPics = state.GlueRollPictures.filter(m => m.glueRollSurveyID === gr.id && !m.deleted)
            grPics.map(gp => {
                if (gp.roll1ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll1ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll1ImageID)
                }
                if (gp.roll2ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll2ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll2ImageID)
                }
                if (gp.roll3ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll3ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll3ImageID)
                }
                if (gp.roll4ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll4ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll4ImageID)
                }
                if (gp.roll5ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll5ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll5ImageID)
                }
                if (gp.roll6ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll6ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll6ImageID)
                }
                if (gp.roll7ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll7ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll7ImageID)
                }
                if (gp.roll8ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll8ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll8ImageID)
                }
                if (gp.roll9ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll9ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll9ImageID)
                }
                if (gp.roll10ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll10ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll10ImageID)
                }
                if (gp.roll11ImageID !== '00000000-0000-0000-0000-000000000000' && gp.roll11ImageID !== '') {
                    value += 1
                    pushIdInList(gp.roll11ImageID)
                }
            })
        })
        return value
       }
       if (from === 'IsBhsList') {
        let value = 0
        const AllChecklist = state.Checklist.filter(ch => ch.visitID == props.VisitId && !ch.deleted);
        AllChecklist.forEach(ch => {
            let items = SelChecklistItemsByCLID(state, ch.id)
            items.forEach(e => {
                if (e.imageID !== '00000000-0000-0000-0000-000000000000' && e.imageID !== '') {
                    pushIdInList(e.imageID)
                    value += 1
                }
            });
        });
        return value
       }
       if (from === 'IsThermalImg') {        
        let value = 0
        const AllThermals = state.Thermals.filter(th => th.visitID == props.VisitId && !th.deleted);
        AllThermals.forEach((th, i) => {
            let images = state.ThermalImages.filter(ti => ti.thermalImageSurveyID == th.id && !ti.deleted)
            images.forEach(img => {
                if (img.leftImageID !== '00000000-0000-0000-0000-000000000000' && img.leftImageID !== '') {
                    pushIdInList(img.leftImageID)
                    value += 1
                }
                if (img.rightImageID !== '00000000-0000-0000-0000-000000000000' && img.rightImageID !== '') {
                    pushIdInList(img.rightImageID)
                    value += 1
                }
            })
        });        
        return value
       }
       if (from === 'IsFormulas') {        
        let value = 0
        const Allformulas = state.Formulas.filter(f => f.plantID === props.plantID && !f.deleted && f.active);
        Allformulas.forEach((e) => {
            if (e.signatureImageID !== '00000000-0000-0000-0000-000000000000' && e.signatureImageID !== '' && e.signatureImageID) {
                pushIdInList(e.signatureImageID)
                value += 1
            }
        });        
        return value
       }
    }

    return (
        <Dialog fullWidth maxWidth={"md"} open={props.modalOpen} >
            <DialogTitle sx={{ borderBottom: 1, borderColor: 'lightgray' }} >
                <Grid container spacing={3}>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                        Select the items that you want to print
                    </Grid>
                    <Grid item xs={6} sx={{}}>
                        <DialogActions>
                            { Loader ?
                                 <div style={{ marginRight: '12px', height: '36px' }}><CircularProgress /></div> : <Button style={{ marginRight: '20px'}} onClick={gotrdtPrint} >Generate Report Now</Button>
                            }
                            
                            {/* <Button>Email report</Button> */}
                            <Button onClick={props.closeModal}>Close</Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogTitle >
            <DialogContent>
                <DataForm>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{}}>
                            <div style={{ paddingLeft: '0px' }} >
                                { ItemError ?  <Typography style={{ paddingTop: '10px' }} color="error">Please check atleast one option</Typography> : null}
                                <FormControlLabel checked={IsSelectAll} onChange={handleCheckAll} control={<Checkbox />} label="Select All" />
                                <div style={{ paddingLeft: '30px' }} >
                                    <FormControlLabel checked={IsKeyItem} onChange={handleCheckItems} control={<Checkbox />} label="Key Items (all)" />
                                    <br></br>
                                    <FormControlLabel checked={IsActionItems} onChange={handleActionItems} control={<Checkbox />} label="Action Items" />
                                    <br></br>
                                    <FormControlLabel checked={IsAllSurvey} onChange={handleCheckSurvey} control={<Checkbox />} label="Surveys" />
                                    <div style={{ paddingLeft: '30px', display: 'block' }} >
                                        <FormControlLabel checked={IsSelectGluLine} onChange={e => (handleSItemCheck(e.target.checked, 'IsSelectGluLine'))} control={<Checkbox />} label="Glue Line" />
                                       <br></br>
                                        <FormControlLabel checked={IsFormulas} onChange={e => (handleSItemCheck(e.target.checked, 'IsFormulas'))} control={<Checkbox />} label="Formula" />
                                       {IsFormulas && (
                                            <FormControl style={{ width: '50%' }}>
                                                <InputLabel>Select Unit Type</InputLabel>
                                                <Select value={unitType} onChange={handleUnitChange}>
                                                    <MenuItem value="imperial">Imperial</MenuItem>
                                                    <MenuItem value="metric">Metric</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                       <br></br>
                                        <FormControlLabel checked={IsGluRolM} onChange={e => (handleSItemCheck(e.target.checked, 'IsGluRolM'))} control={<Checkbox />} label="Glue Roll Measurement" />
                                       <br></br>
                                        <FormControlLabel checked={IsGluRolP} onChange={e => (handleSItemCheck(e.target.checked, 'IsGluRolP'))} control={<Checkbox />} label="Glue Roll Pictures" />
                                       <br></br>
                                        <FormControlLabel checked={IsHeatVe} onChange={e => (handleSItemCheck(e.target.checked, 'IsHeatVe'))} control={<Checkbox />} label="Heat Vessel" />
                                       <br></br>
                                        <FormControlLabel checked={IsIngredent} onChange={e => (handleSItemCheck(e.target.checked, 'IsIngredent'))} control={<Checkbox />} label="Ingredent Verification" />
                                        <br></br>
                                        <FormControlLabel checked={IsStartchKi} onChange={e => (handleSItemCheck(e.target.checked, 'IsStartchKi'))} control={<Checkbox />} label="Starch Kitchen" />
                                       <br></br>
                                        <FormControlLabel checked={IsInventory} onChange={e => (handleSItemCheck(e.target.checked, 'IsInventory'))} control={<Checkbox />} label="Inventory" />
                                       <br></br>
                                        <FormControlLabel checked={IsStartchCheck} onChange={e => (handleSItemCheck(e.target.checked, 'IsStartchCheck'))} control={<Checkbox />} label="Starch Checks" />
                                       <br></br>
                                        <FormControlLabel checked={IsBhsList} onChange={e => (handleSItemCheck(e.target.checked, 'IsBhsList'))} control={<Checkbox />} label="BHS WCS Checklist" />
                                       <br></br>
                                        <FormControlLabel checked={IsThermalImg} onChange={e => (handleSItemCheck(e.target.checked, 'IsThermalImg'))} control={<Checkbox />} label="Thermal Images" />
                                       <br></br>
                                        <FormControlLabel checked={IsCiList} onChange={e => (handleSItemCheck(e.target.checked, 'IsCiList'))} control={<Checkbox />} label="Continuous Improvement" />
                                       <br></br>
                                        <FormControlLabel checked={IsStarchGraph} onChange={e => (handleSItemCheck(e.target.checked, 'IsStarchGraph'))} control={<Checkbox />} label="Starch Graph" />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DataForm>
            </DialogContent>
        </Dialog >
    );
};