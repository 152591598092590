import Constants from '../../constants';
//Modules
import React, { useState } from 'react'
import produce from "immer";
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-mui';
//MUI
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
//Components
import { FormulaTypeSel } from './FormulaTypeSel';
import { NativeSelectFormik } from '../FormikUI/NativeSelectFormik';
//Actions
import { AddFormula, CopyFormula } from "../../actions/FormulaActions";
const constants = Constants;
export const FormulaSelection = props => {
    const selID = props.selID ? props.selID : "formula_selector"
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();
    const formulas = useSelector(state => state.Formulas.filter(f => f.plantID === props.plantID && !f.deleted));
    const options = formulas.map(l =>
        <option
            key={selID+l.id}
            value={l.id}
        >
            {(l.active?'*':'')+l.name}
        </option>
    );
    options.unshift(<option key={'0'} value={'0'}>Select Formula</option>);
    let addDialog = null;
    
    const defaultChange = (e) => {
        if (e.target.value !== '9999') {
            props.handleChange(e.target.value);
        } else {
            setOpen(true);
        }
    };
    const onChange = props.onChange ? props.onChange : defaultChange;
    const newSubmit = (vals) => {
        vals.plantID = props.plantID;
        if (vals.copyFromID !== '0') {
            dispatch(CopyFormula(vals, vals.copyFromID));
        } else {
            dispatch(AddFormula(vals));
        }    
        setOpen(false);
    }
    if (props.addLine) {
        addDialog = <AddNewFormulaModal isOpen={isOpen} closeModal={() => { setOpen(false); }} onSubmit={newSubmit} plantID={props.plantID} />;
        options.push(<option key={'9999'} value={'9999'}>Add New Formula</option>)

    }
    const selected = props.selected ? props.selected : '0';
    const label = props.label ? props.label : 'Formula:';
    return (
        <>
            <FormControl >
                <InputLabel htmlFor={selID}>{ label }</InputLabel>
                <NativeSelect
                    id={ selID }
                    name={props.name}
                    value={selected}
                    onChange={onChange}
                >
                    {options}
                </NativeSelect>
                <FormHelperText></FormHelperText>
            </FormControl>
            {addDialog}
        </>
    );
}
const AddNewFormulaModal = props => {
    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Required";
        }
        return errors;
    }
    return <Dialog open={props.isOpen} onClose={props.closeModal} >
        <Formik initialValues={{ name: '', calculationType: 0, copyFromID: '0' }} onSubmit={props.onSubmit} validate={validate}>
            {({ values,handleChange }) =>
                <Form>
                    <DialogTitle >Add Formula</DialogTitle >
                    <DialogContent>
                        <div style={{ padding: "10px" }}>
                            <Field
                                component={TextField}
                                name="name"
                                label="Name"
                            />
                        </div>
                        <div style={{ padding: "10px" }}>
                            <Field
                                component={FormulaTypeSelFormik}
                                name="calculationType"
                                label="Type"
                            />
                        </div>
                        <div style={{ padding: "10px" }}>

                            <FormikFormulaSel plantID={props.plantID} handleChange={handleChange} value={values.copyFromID} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit">Add</Button>
                        <Button onClick={props.closeModal}>Cancel</Button>
                    </DialogActions>
                </Form>
            }
        </Formik>
    </Dialog >;
};
const FormikFormulaSel = props => {

    return <FormulaSelection plantID={props.plantID} onChange={props.handleChange}
        name={'copyFromID'} selID={"copyFromForm_Sel"} label="Copy From:"  selected={props.value} />;
}
const FormulaTypeSelFormik = props => {
    const handleChange = (e) => {
        const newNum = Number(e.target.value);
        if (!isNaN(newNum)) {
            props.form.setFieldValue('calculationType', newNum);
        }
    }
    return <FormulaTypeSel value={props.field.value} onChange={handleChange} />;
};
const FormulaTemplateSelFormik = props => {
  
    const typeOptions = [
        <option key={'f_template_0'} value={0}>Type 2</option>,
        <option key={'f_template_1'} value={1}>Westrock</option>,
        <option key={'f_template_2'} value={2}>IP Type</option>,
    ];

    return <NativeSelectFormik
        name="template"
        label="Template"
        options={typeOptions}
    />
};