import Constants from '../../constants';
//Modules
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import produce from 'immer';
import { ScaleImages } from '../../misc/ImageFuncs';
import { AttachmentsByFilterArray } from '../../selectors/AttachmentSelectors';
import { ErrorNotification } from '../../actions/NotificationActions';

//MUI

import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ReportViewer from '../Report/ReportViewer';
//Components
import { useReportUser, useReportNameFromPlantID, usePlantDataFromVisitID } from '../../misc/UseFuncs';
//Selectors
import { GetHeatVesselByID, GetBoilers, GetHVTempsByHVID } from '../../selectors/HeatVesselSelectors';
import { selActionItems, selActionItemsByPlantID } from '../../selectors/ActionItemSelectors';
import { GetAttachmentsByArray, GetAttachment, DeleteAttachment } from '../../actions/AttachmentActions';
import { MachinesFilteredByArray, MachinesAddFlutesString } from '../../selectors/PlantSelectors';
import { SelChecklistByID, SelChecklistGroupsByCLID, SelChecklistItemsByCLID } from '../../selectors/ChecklistSelectors';
import { selectKeyItemsByVisitID, selectMultiKeyItems } from '../../selectors/KeyItemSelectors';
import { PopulateFormulaStepsStrings, SelFormulaByID } from '../../selectors/FormulaSelectors';
import { GetIngredientVerificationByID, GetIVStepsByParentID } from '../../selectors/IngredientVerificationSelectors';
import { SelVisitByID, SelInventoryByVisitID, SelMeasuresByVisitID, FormatLocation, SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
import { GetGLMachinesByGLID } from '../../selectors/GlueLineSelectors';
import { GlueRollByIDSel, FilteredMachineFlutesSel } from '../GlueRoll/GlueRollSelectors';
import { useHeatVeseelPrintData } from '../ContinuosImprove/ConImpPrintView';
import { getCIValue } from './GetCiData';
import { StepToLabel } from '../Formula/StepTypeViews';
import { getFormulaValue } from './GetFormulaValue';
import { GetGraphData } from './GetGraphData';
const VisitGlobalPrint = props => {
    const savedIdList = JSON.parse(localStorage.getItem('IdList')) || null;
    
    
    const dispatch = useDispatch();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const jsonData = queryParams.get('data');
    const options = JSON.parse(jsonData);
    const [UserData, setUserData] = useState(null);
    const [finalObj, setfinalObj] = useState(null);
    const [isload, setisload] = useState(true);
    const params = useParams();
    const vID = params.VisitID;
    const navigate = useNavigate();
    const handleClose = () => navigate(-1);
    const state = useSelector(s => s);
    const visit = state.Visits.find((v) => v.id == vID)
    const plant = state.Plants.find(p => p.id === visit?.plantID);
    const [actionItemsData, setActionItemsData] = useState(null);
    const actionItems = useSelector(state => state.ActionItems.filter(ai => ai.plantID === plant?.id && !ai.deleted));
    const [scaledAttachments, setScaledAttachments] = useState([]);
    const [scaled, setScaled] = useState(false);
    // const imageIDs=actionItems.filter(ai=>ai.imageID!=Constants.EmptyGuid).map(ai=>ai.imageID);
    const imageIDs = actionItems.filter(ai => ai.imageID !== Constants.EmptyGuid).map(ai => ai.imageID);

    let userObj = state.Auth.userData
    const [AllAttachmentArr, setAllAttachmentArr] = useState([]);
    const tempkeyItems = selectKeyItemsByVisitID(state, vID);
    const keyItems = tempkeyItems.map((i, ind) => { return { ...i, lineNo: ind + 1, isItemPriorty1: i.isPriority === 'Yes' ? ' (Priority)' : '', isItemPriorty: i.isPriority , isImageData: i.imageID !== '00000000-0000-0000-0000-000000000000' ? true : false } });
    const Allattachments = state.Attachments.filter(a => !a.deleted);
    
    const [VisitMeasurements, setVisitMeasurements] = useState(null);
    const [skItems, setskItems] = useState(null)
    const [glueLine, setglueLine] = useState(null)
    const [gluRolePcs, setgluRolePcs] = useState(null)
    const [HeatVesselObjectChild, setHeatVesselObjectChild] = useState(null)
    const [BCSCheckListObjectChild, setBCSCheckListObjectChild] = useState(null)
    const [IngredientVerObjectChild, setIngredientVerObjectChild] = useState(null)
    const inventories = useSelector(state => SelInventoryByVisitID(state, vID));
    const [ThermalObjectChild, setThermalObjectChild] = useState(null)
    const [BaselineObjectChild, setBaselineObjectChild] = useState(null)
    const [FornulaObjectChild, setFornulaObjectChild] = useState(null)
    const [StarchGraphObjectChild, setStarchGraphObjectChild] = useState(null)
    const [gluRoleMeasurment, setgluRoleMeasurment] = useState(null)
    const [isapiCall, setisapiCall] = useState(false)
    const [imageDownloadSize, setimageDownloadSize] = useState(0)
    const [ShowSizeAlert, setShowSizeAlert] = useState(false)
    const [ShowOptionSelectAlert, setShowOptionSelectAlert] = useState(false)
    const [downLoadedImages, setDownloadedImages] = useState(null)
    const [callDownloadImage, setcallDownloadImage] = useState(false)
    const [visitDate, setvisitDate] = useState(null)
    const [DeJons, setDeJons] = useState(null)
    const [djonLoad, setdjonLoad] = useState(false)
    const [isLoadReport, setisLoadReport] = useState(false)
    const [ShowErrAlert, setShowErrAlert] = useState(false)
    const [DownloadImgCount, setDownloadImgCount] = useState(0)
    const scaledRef = useRef(false);
    const LastSync=useSelector(s=>s.LastSync,shallowEqual);
    const Token=useSelector(s=>s.Auth.token,shallowEqual);
    const unitType = queryParams.get('unitType');
    const [isMetric, setIsMetric] = useState(unitType === 'metric');
    // const [isMetric, setIsMetric] = useState(true);
    const fetchInProgressRef = useRef(false);
    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];
    
    const convertBytesToMb = (bytesValue) => {
        let mbValue = bytesValue / (1024 * 1024); // 1 MB = 1024 * 1024 bytes
        return Math.ceil(mbValue);
    };
    useEffect(() => {
        if (savedIdList == null && finalObj == null) {
            navigate(`/VisitOverview/${vID}?${'printOption'}`)
        }
    }, [savedIdList]);
    
    // useEffect(() => {
    //     if (savedIdList == null && !finalObj) {
    //         navigate(`/VisitOverview/${vID}?${'printOption'}`);
    //     }
    // }, [savedIdList, finalObj]); // Ensure `finalObj` is checked properly before navigation
    
    useEffect(() => {
        if(visit){
            const d=new Date(visit.startDate);
            let visitDate = (d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
            setvisitDate(visitDate)
        }
    }, [visit]);

  useEffect(() => {
    if (actionItems.length > 0 && !scaledRef.current && !fetchInProgressRef.current) {
      fetchInProgressRef.current = true;

      const imageIDs = actionItems
        .filter((ai) => ai.imageID !== Constants.EmptyGuid)
        .map((ai) => ai.imageID);
      console.log("Image IDs being fetched: ", imageIDs);

      GetAttachmentsByArray(actionItems,dispatch,LastSync,Token)
      Promise.all(imageIDs.map((id) => Promise.resolve(`Scaled image: ${id}`)))
        .then((values) => {
          console.log("Scaled Images: ", values);
          setScaledAttachments(values); 
          scaledRef.current = true;
          fetchInProgressRef.current = false;
        })
        .catch((error) => {
          console.error("Error scaling images:", error);
          fetchInProgressRef.current = false;
        });
    }
  }, [actionItems]);


    useEffect(() => {
        console.log("Updated Scaled Attachments:", scaledAttachments);
    }, [scaledAttachments]);    

    useEffect(() => {
        if (scaledRef.current && actionItems.length > 0 && !actionItemsData) {
            console.log("Formatting action items data");

            const actions = actionItems.map(ai => {
                let statusText = '';
                switch (ai.status) {
                    case 0: statusText = "Open"; break;
                    case 1: statusText = "Closed"; break;
                    case 2: statusText = "WIP"; break;
                    case 3: statusText = "Cancelled"; break;
                    default: statusText = "Unknown"; break;
                }

                let championName = '';
                const champion = state.Personnel.find(p => p.id === ai.championID);
                if (champion) {
                    championName = champion.name;
                } else if (ai.championID === '11111111-1111-1111-1111-111111111111') {
                    championName = 'CCI';
                }
                

                return { ...ai, statusText, championName };
            });

            const actionItemData = {
                ActionItems: actions,
                Attachments: scaledAttachments
            };

            setActionItemsData(actionItemData); 
        }
    }, [scaledAttachments, actionItems, state, actionItemsData]); 
    
    useEffect(() => {        
        if (DeJons !== null && djonLoad && state.Auth.token) {
            if (state.offline.online) {
                fetch('api/Attachments/totalSize', {
                    method: 'POST',
                    headers: {
                      'Authorization': 'Bearer ' + state.Auth.token,
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(savedIdList)
                  })
                    .then(response => {
                      if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                      }
                      return response.json();
                    })
                    .then(size => {
                        if (size.totalSize > 0) {
                            setimageDownloadSize(convertBytesToMb(size.totalSize))
                        } else {
                            setisLoadReport(true)
                        }
                    })
                    .catch(error => {
                      console.log('error---------------', error);
                      setShowErrAlert(true)
                    });
            }  else {
                setShowErrAlert(true)
            }
            
        }
    }, [DeJons, state.Auth?.token]);
    useEffect(() => {
        if (imageDownloadSize > 0) {
            setShowSizeAlert(true)
        }
    }, [imageDownloadSize]);
    useEffect(() => {
        if (savedIdList === null) {
            setShowOptionSelectAlert(true)
        } else {
            setDownloadImgCount(savedIdList.length)
        }
    }, [savedIdList]);
    

    useEffect(() => {
            if (options.IsStartchCheck) {
                let VisitMeasurements = SelMeasuresByVisitID(state, vID).map(m => {
                    return { ...m, locationName: FormatLocation(m.location) };
                });
                setVisitMeasurements(VisitMeasurements)
            } else {
                setVisitMeasurements([])
            }
            if (options.IsStartchKi) {
            let  skItems = SelSKItemsByVisitID(state, vID);
                skItems.forEach(element => {
                    element.isChecked = element.checked
                });
                setskItems(skItems)
            } else {
                setskItems([])
            }
            if (options.IsSelectGluLine) {
                let glueLine = []
                const glueLineTemp = state.GlueLines.filter(i => i.visitID == vID && !i.deleted)
                glueLineTemp.forEach(gl => {
                    let data = {
                        // GlueLine: gl,
                        GLMachines: getMachins(gl)
                    }
                    if (data.GLMachines !== null) {
                        glueLine.push(data)
                    }
                });
                setglueLine(glueLine)
            } else {
                setglueLine([])
            }
            // Get GlueLineMeasureObjectChild list
            if (options.IsGluRolM) {
                const glueRoles = state.GlueRolls.filter(gr => gr.visitID == vID && !gr.deleted)
                let gluRoleMeasurment = []
                glueRoles.forEach(gr => {
                    let data = {
                        Measurements: getGRMeasurement(gr.id)
                    }
                    if (data.Measurements.length > 0) {
                        gluRoleMeasurment.push(data)
                    }
                })
                setgluRoleMeasurment(gluRoleMeasurment)
            } else {
                setgluRoleMeasurment([])
            }
            if (options.IsGluRolP) {
                const glueRoles = state.GlueRolls.filter(gr => gr.visitID == vID && !gr.deleted)
                let gluRolePcs = []
                glueRoles.forEach(gr => {
                    gluRolePcs.push({ GRPictures:  state.GlueRollPictures.filter(m => m.glueRollSurveyID === gr.id && !m.deleted) })
                })
                setgluRolePcs(gluRolePcs)                    
            } else {
                setgluRolePcs([])
            }
            if (options.IsHeatVe) {
                const AllheatVessel = state.HeatVessels.filter(hvs => hvs.visitID == vID && !hvs.deleted);
                let HeatVesselObjectChild = []
                AllheatVessel.forEach(hv => {
                    let HVTemps = GetHVTempsByHVID(state, hv.id)
                    HVTemps.forEach(e => {
                        e.isKyeItem = getIsKeyItem(e.id)
                    });
                    let tempMachin = MachinesFilteredByArray(state, HVTemps);
                    tempMachin = MachinesAddFlutesString(state, tempMachin);
                    tempMachin.forEach(e => {
                        e.mapId = hv.id
                    });
                    hv.isKyeItem = getIsKeyItem(hv.id)
                    hv.Machines = tempMachin
                    
                    
                    let HeatVesselObject = {
                        HeatVessel: hv,
                        HVTemps: HVTemps,
                        Boilers: GetBoilers(state, hv?.lineID),
                        // Machines: tempMachin,
                    }
                    if (HeatVesselObject.HeatVessel.lineID !== "00000000-0000-0000-0000-000000000000") {
                        HeatVesselObjectChild.push(HeatVesselObject)
                    }
                });
                setHeatVesselObjectChild(HeatVesselObjectChild)
            } else {
                setHeatVesselObjectChild([])
            }
            if (options.IsBhsList) {
                let BCSCheckListObjectChild = []
                const AllChecklist = state.Checklist.filter(ch => ch.visitID == vID && !ch.deleted);
                AllChecklist.forEach(ch => {
                    let items = SelChecklistItemsByCLID(state, ch.id)
                    items = items.map(e => ({
                        ...e,
                        isKyeItem: getIsKeyItem(e.id)
                    }));
                    let chck = {
                        Checklist: ch,
                        Groups: SelChecklistGroupsByCLID(state, ch.id),
                        Items: items,
                    }
                    BCSCheckListObjectChild.push(chck)
                });
                setBCSCheckListObjectChild(BCSCheckListObjectChild)
            } else {
                setBCSCheckListObjectChild([])
            }
            // Get IngredientVerObjectChild DATA
            if (options.IsIngredent) {
                const AllInVerific = state.IngredientVerifications.filter(ch => ch.visitID == vID && !ch.deleted);
                let IngredientVerObjectChild = []
                AllInVerific.forEach(iv => {
                    if (iv?.formulaID) {
                    let formula = SelFormulaByID(state, iv?.formulaID);
                    let steps = GetIVStepsByParentID(state, iv.id); 
                    // console.log('GetIVStepsByParentID--IsIngredent--123456789', steps);
                    const updatedSteps = steps.map(e => ({
                        ...e, // Copy existing properties
                        stepTypeText: getStep(e.stepType), // Add the new property
                        unitText: getStep(e.unit), // Add the new property
                    }));
                    console.log('GetIVStepsByParentID--IsIngredent--123', updatedSteps);
                    let inventory = SelInventoryByVisitID(state, iv.visitID);
                    let ivObj = {
                        IngredientVerification: {...iv, batchWeight:  formula?.batchWeight},
                        IVSteps: updatedSteps,
                        Inventory: inventory,
                        isNull: inventory.length,
                        Formula: formula,
                    }
                        IngredientVerObjectChild.push(ivObj)
                    }
                });
                setIngredientVerObjectChild(IngredientVerObjectChild)
            } else {
                setIngredientVerObjectChild([])
            }
            if (options.IsThermalImg) {
                const AllThermals = state.Thermals.filter(th => th.visitID == vID && !th.deleted);
                let ThermalObjectChild = []
                AllThermals.forEach((th, i) => {
                    let images = state.ThermalImages.filter(ti => ti.thermalImageSurveyID == th.id && !ti.deleted)
                    let ivObj = {
                        HeaderObj: { addedDate: th.createdDate, mappingKey: i, plantName: plant.name, LineName: th.name, ThermalDataObj: images }
                    }
                    if (ivObj.HeaderObj.LineName !== null) {
                        ThermalObjectChild.push(ivObj)
                    }
                });
                setThermalObjectChild(ThermalObjectChild)
            } else {
                setThermalObjectChild([])
            }
            if (options.IsCiList) {
                const AllContImprove = state.ContImprove.filter(Ci => Ci.visitID == vID && !Ci.deleted)
                let BaselineObjectChild = []
                AllContImprove.forEach(ci => {
                    const cidata = getCIValue(ci, plant, state)
                    // const cidata = GetCiData(ci, plant, state)
                    if (cidata) {
                        let cdata = {
                            Baseline: {...cidata.Baseline, ciMapping: ci.id},
                            Waste: {...cidata.Waste, ID: cidata.Baseline.ID, ciMapping: ci.id},
                            Training: {...cidata.Training, ID: cidata.Baseline.ID, ciMapping: ci.id},
                            Downtime: {...cidata.Downtime, ID: cidata.Baseline.ID, ciMapping: ci.id},
                            Consumption: {...cidata.Consumption, ID: cidata.Baseline.ID, ciMapping: ci.id},
                            Production: {...cidata.Production, ID: cidata.Baseline.ID, ciMapping: ci.id},
                            FooterObject: {...cidata.FooterObject, ID: cidata.Baseline.ID, ciMapping: ci.id},
                        }
                        BaselineObjectChild.push(cdata)
                    }
                });
                setBaselineObjectChild(BaselineObjectChild)
            } else {
                setBaselineObjectChild([])
            }
            if (options.IsFormulas) {
                const Allformulas = state.Formulas.filter(f => f.plantID === visit?.plantID && !f.deleted && f.active);
                let formulaChildData = [];
                Allformulas.forEach(fl => {
                    const Fdata = getFormulaValue(fl, plant, state, isMetric); 
                    if (Fdata) {
                        formulaChildData.push(Fdata);
                    }
                });
                setFornulaObjectChild(formulaChildData);
            } else {
                setFornulaObjectChild([]);
            }
            if (options.IsStarchGraph) {
                let data = GetGraphData(options.IsStarchGraph,plant, state)
                data.isShowStarchGraphReport = options.IsStarchGraph ? true : false
                setStarchGraphObjectChild(data)
            } else {
                let data = GetGraphData(options.IsStarchGraph,plant, state)
                data.isShowStarchGraphReport = false
                setStarchGraphObjectChild(data)
            }
    }, [options.IsFormulas, isMetric]);

    function getMachins(gl) {
        let maschins = GetGLMachinesByGLID(state, gl.id)
        if (maschins.length > 0) {
            maschins.forEach(m => {
                m.isSFKeyItem = getGLIsKeyItem(m.id, m.sfMachineName)
                m.isGUKeyItem = getGLIsKeyItem(m.id, m.guMachineName)
                m.GUImage1 = getImage(m.imageID)
                m.GUImage2 = getImage(m.secondImageID)
                m.SFImage1 = getImage(m.sfImageID)
                m.SFImage2 = getImage(m.sfSecondImageID),
                m.boardCombo = gl.boardCombo,
                m.topDeck = gl.topDeck,
                m.middleDeck = gl.middleDeck,
                m.fluteName = gl.fluteName,
                m.bottomDeck = gl.bottomDeck
            })
            return  maschins
        }
        return null 
    }
    function getGLIsKeyItem(id, name) {
        let temp = false
        keyItems.forEach(e => {
            if (e.entityID === id && e.entityField === name ) {
                temp = true
            }
        });
        return temp
    }
    function getIsKeyItem(id) {
        let temp = false
        keyItems.forEach(e => {
            if (id === e.entityID) {
                temp = true
            }
        });
        return temp
    }
    function getImage(id) {
        let temp = ''
        AllAttachmentArr.forEach(at => {
            if (id === at.id) {
                temp = 'data:image/jpeg;base64,'+at.data
            }
        });
        return temp
    }
    function getGrImage(id) {
        let temp = ''
        AllAttachmentArr.forEach(at => {
            if (id === at.id) {
                temp = at.data
            }
        });
        return temp
    }

    function getGRMeasurement(id) {
        let measurements = state.GlueRollMeasurements.filter(m => m.glueRollSurveyID === id && !m.deleted)
        const MachineFlutes = FilteredMachineFlutesSel(state, measurements);
        const MeasurementsWithFlutes = measurements.map(m => {
            let fluteStr = '';
            MachineFlutes.filter(mf => mf.machineID === m.machineID && !mf.deleted)
                .forEach(f => {
                    const FluteName = Object.keys(Constants.Enums.Flutes).find(key => Constants.Enums.Flutes[key] === f.flute);
                    if (fluteStr !== '') {
                        fluteStr += ', ' + FluteName;
                    } else {
                        fluteStr = FluteName;
                    }
                });

                let oTotal = m.glueRollOperator + m.meterRollOperator
                let cTotal = m.glueRollCenter + m.meterRollCenter
                let dTotal = m.glueRollDrive + m.meterRollDrive
                

            return { ...m, flutes: fluteStr, oTotal:oTotal, cTotal:cTotal, dTotal:dTotal   };
        });
        return MeasurementsWithFlutes
    }
    function getStep(stepsArray) {
        // Ensure stepsArray is always processed as an array
        if (!Array.isArray(stepsArray)) {
            stepsArray = [stepsArray];
        }
    
        const stepTypeText = Object.keys(Constants.Enums.StepType).find(
            key => Constants.Enums.StepType[key] == stepsArray[0]
        );
    
        const unitText = stepsArray.map(s => {
            const unitText = Object.keys(Constants.Enums.FormulaUnit).find(
                key => Constants.Enums.FormulaUnit[key] == s.unit
            );
    
            return {
                ...s,
                unitText: StepToLabel(unitText),
            };
        });
    
        return StepToLabel(stepTypeText, unitText);
    }
    

    useEffect(() => {
        if (userObj) {
            setUserData(userObj)
        }
    }, [userObj]);

    // const printObj = null
    // const printObj = useGetPrintData(vID, visit, state, UserData, plant, options);



    useEffect(() => {
        if (userObj) {
            setUserData(userObj)
        }
    }, [userObj]);


    useEffect(() => {
        if (gluRolePcs !== null && keyItems !== null && glueLine  !== null && gluRoleMeasurment  !== null && HeatVesselObjectChild !== null && BCSCheckListObjectChild !== null && IngredientVerObjectChild !== null && VisitMeasurements !== null && skItems !== null && ThermalObjectChild !== null && BaselineObjectChild ) { 
        setTimeout(() => {
          if (!djonLoad) {
           setDeJons(
            {
                Default: {
                    User: UserData,
                    Plant: plant,
                    Visit: visit
                },
                keyItemObject: {
                    isShowKeyItemReport: options.IsKeyItem,
                    isNull: options.IsKeyItem ? keyItems  === null ? true : false : keyItems.length > 0 ? false : true,
                    keyItems: keyItems === null ? [] : keyItems 
                },
                ActionItemsObject: {
                    isShowActionItemsReport: options.IsActionItems,
                    isNull: options.IsActionItems
                        ? (actionItemsData === null || actionItemsData.length === 0 ? true : false)
                        : true,
                    ActionItems: options.IsActionItems
                        ? (actionItemsData === null || actionItemsData.length === 0 ? [] : actionItemsData)
                        : []
                },
                GlueLineObject: {
                    isShowGlueLineReport: options.IsSelectGluLine,
                    isNull: options.IsSelectGluLine &&  glueLine === null ? true : glueLine.length > 0 ? false : true,
                    GlueLineObjectChild: glueLine === null ? [] : glueLine
                },
                GlueLineMeasureObject: {
                    isShowGlueLineMeasureReport: options.IsGluRolM,
                    isNull: options.IsGluRolM &&  gluRoleMeasurment  === null ? true: gluRoleMeasurment.length > 0 ? false : true,
                    GlueLineMeasureObjectChild: gluRoleMeasurment=== null ? [] : gluRoleMeasurment
                },
                GlueLinePictureObject: {
                    isShowGlueLinePictureReport: options.IsGluRolP,
                    isNull: options.IsGluRolP &&  gluRolePcs  === null ? true: gluRolePcs.length > 0 ? false : true,
                    GlueLinePictureObjectChild: gluRolePcs === null ? [] : gluRolePcs 
                },
                HeatVesselObject: {
                    isShowHeatVesselReport: options.IsHeatVe,
                    isNull: options.IsHeatVe &&  HeatVesselObjectChild === null ? true: HeatVesselObjectChild.length > 0 ? false : true,
                    HeatVesselObjectChild: HeatVesselObjectChild=== null ? [] : HeatVesselObjectChild
                },
                BCSCheckListObject: {
                    isShowHBCSCheckListReport: options.IsBhsList,
                    isNull: options.IsBhsList &&  BCSCheckListObjectChild === null ? true: BCSCheckListObjectChild.length > 0 ? false : true,
                    BCSCheckListObjectChild: BCSCheckListObjectChild=== null ? [] : BCSCheckListObjectChild
                },
                IngredientVerObject: {
                    isShowIngredientVerObjectReport: options.IsIngredent,
                    isNull: options.IsIngredent &&  IngredientVerObjectChild === null ? true: IngredientVerObjectChild.length > 0 ? false : true,
                    IngredientVerObjectChild: IngredientVerObjectChild=== null ? [] : IngredientVerObjectChild
                },
                InventoryObject: {
                    isShowInventoryReport: options.IsInventory,
                    isNull: options.IsInventory
                        ? (inventories === null || inventories.length === 0 ? true : false)
                        : true,
                        inventories: options.IsInventory
                        ? (inventories === null || inventories.length === 0 ? [] : inventories)
                        : []
                },
                // InventoryObject: {
                //     isShowInventoryReport: options.IsInventory,  // Assuming you have this option
                //     isNull: options.IsInventory &&  inventories === null ? true: inventories.length > 0 ? false : true,
                //     inventories: inventories=== null ? [] : inventories
                // },
                StarchChecksObject: {
                    isShowStarchChecksObjectReport: options.IsStartchCheck,
                    isNull: options.IsStartchCheck &&  VisitMeasurements === null ? true: VisitMeasurements.length > 0 ? false : true,
                    Measurements: VisitMeasurements === null ? [] : VisitMeasurements 
                },
                StarchKitchenObject: {
                    isShowStarchKitchenObjectReport: options.IsStartchKi,
                    isNull: options.IsStartchKi &&  skItems === null ? true: skItems.length > 0 ? false : true,
                    SKItems: skItems=== null ? [] : skItems
                },
                ThermalObject: {
                    isShowThermalObjectReport: options.IsThermalImg,
                    isNull: options.IsThermalImg &&  ThermalObjectChild === null ? true: ThermalObjectChild.length > 0 ? false : true,
                    ThermalObjectChild: ThermalObjectChild=== null ? [] : ThermalObjectChild
                },
                BaselineObject: {
                    isShowBaselineObjectReport: options.IsCiList,
                    isNull: options.IsCiList &&  BaselineObjectChild === null ? true: BaselineObjectChild.length > 0 ? false : true,
                    BaselineObjectChild: BaselineObjectChild=== null ? [] : BaselineObjectChild
                },
                StarchGraph: StarchGraphObjectChild,
                AllFormulas: {
                    isShowAllFormulaReport: options.IsFormulas,
                    isNull: options.IsFormulas &&  FornulaObjectChild === null ? true: FornulaObjectChild.length > 0 ? false : true,
                    AllFormulaObjectChild: FornulaObjectChild,
                },
            }
           )
           setdjonLoad(true)
          }
        }, 100);
        }
    }, [keyItems, actionItemsData, glueLine, gluRoleMeasurment, gluRolePcs, HeatVesselObjectChild, BCSCheckListObjectChild, IngredientVerObjectChild, inventories, VisitMeasurements, skItems, ThermalObjectChild, BaselineObjectChild, UserData, plant,visit]);


    // const dJSON = {
    //     Default: {
    //         User: UserData,
    //         Plant: plant,
    //         Visit: visit
    //     },
    //     keyItemObject: {
    //         isShowKeyItemReport: options.IsKeyItem,
    //         isNull: options.IsKeyItem ? keyItems  === null ? true : false : false,
    //         keyItems: keyItems === null ? [] : keyItems 
    //     },
    //     GlueLineObject: {
    //         isShowGlueLineReport: options.IsSelectGluLine,
    //         isNull: options.IsSelectGluLine &&  glueLine === null ? true : false,
    //         GlueLineObjectChild: glueLine=== null ? [] : glueLine
    //     },
    //     GlueLineMeasureObject: {
    //         isShowGlueLineMeasureReport: options.IsGluRolM,
    //         isNull: options.IsGluRolM &&  gluRoleMeasurment  === null ? true: false,
    //         GlueLineMeasureObjectChild: gluRoleMeasurment=== null ? [] : gluRoleMeasurment
    //     },
    //     GlueLinePictureObject: {
    //         isShowGlueLinePictureReport: options.IsGluRolP,
    //         isNull: options.IsGluRolP &&  gluRolePcs  === null ? true: false,
    //         GlueLinePictureObjectChild: gluRolePcs === null ? [] : gluRolePcs 
    //     },
    //     HeatVesselObject: {
    //         isShowHeatVesselReport: options.IsHeatVe,
    //         isNull: options.IsHeatVe &&  HeatVesselObjectChild === null ? true: false,
    //         HeatVesselObjectChild: HeatVesselObjectChild=== null ? [] : HeatVesselObjectChild
    //     },
    //     BCSCheckListObject: {
    //         isShowHBCSCheckListReport: options.IsBhsList,
    //         isNull: options.IsBhsList &&  BCSCheckListObjectChild === null ? true: false,
    //         BCSCheckListObjectChild: BCSCheckListObjectChild=== null ? [] : BCSCheckListObjectChild
    //     },
    //     IngredientVerObject: {
    //         isShowIngredientVerObjectReport: options.IsIngredent,
    //         isNull: options.IsIngredent &&  IngredientVerObjectChild === null ? true: false,
    //         IngredientVerObjectChild: IngredientVerObjectChild=== null ? [] : IngredientVerObjectChild
    //     },
    //     StarchChecksObject: {
    //         isShowStarchChecksObjectReport: options.IsStartchCheck,
    //         isNull: options.IsStartchCheck &&  VisitMeasurements === null ? true: false,
    //         Measurements: VisitMeasurements === null ? [] : VisitMeasurements 
    //     },
    //     StarchKitchenObject: {
    //         isShowStarchKitchenObjectReport: options.IsStartchKi,
    //         isNull: options.IsStartchKi &&  skItems === null ? true: false,
    //         SKItems: skItems=== null ? [] : skItems
    //     },
    //     ThermalObject: {
    //         isShowThermalObjectReport: options.IsThermalImg,
    //         isNull: options.IsThermalImg &&  ThermalObjectChild === null ? true: false,
    //         ThermalObjectChild: ThermalObjectChild=== null ? [] : ThermalObjectChild
    //     },
    //     BaselineObject: {
    //         isShowBaselineObjectReport: options.IsCiList,
    //         isNull: options.IsCiList &&  BaselineObjectChild === null ? true: false,
    //         BaselineObjectChild: BaselineObjectChild=== null ? [] : BaselineObjectChild
    //     }
    // }

    useEffect(() => {
        if ( downLoadedImages !== null) {
            let dJSON = DeJons
            let tempCount = 0
            dJSON.keyItemObject.keyItems.forEach(e => {
                const imageObj = downLoadedImages.find((ig) => ig.id === e.imageID)
                if (imageObj) {
                    e.imgData = imageObj.data
                    tempCount += 1
                }
            });
            dJSON.GlueLineObject.GlueLineObjectChild.forEach(e => {
                e.GLMachines.forEach(f => {
                    const GUImage1 = downLoadedImages.find((ig) => ig.id === f.imageID)
                    const GUImage2 = downLoadedImages.find((ig) => ig.id === f.secondImageID)
                    const SFImage1 = downLoadedImages.find((ig) => ig.id === f.sfImageID)
                    const SFImage2 = downLoadedImages.find((ig) => ig.id === f.sfSecondImageID)

                    if (GUImage1) {
                      f.GUImage1 =   GUImage1.data
                      tempCount += 1
                    }
                    if (GUImage2) {
                      f.GUImage2 =   GUImage2.data
                      tempCount += 1
                    }
                    if (SFImage1) {
                      f.SFImage1 =   SFImage1.data
                      tempCount += 1
                    }
                    if (SFImage2) {
                      f.SFImage2 =   SFImage2.data
                      tempCount += 1
                    }
                });
            });
            dJSON.GlueLinePictureObject.GlueLinePictureObjectChild.forEach(e => {
                // Filter out the GRPictures that don't have any images
                e.GRPictures = e.GRPictures.filter(f => {
                    let roll1Imag = downLoadedImages.find((ig) => ig.id === f.roll1ImageID)
                    let roll2Imag = downLoadedImages.find((ig) => ig.id === f.roll2ImageID)
                    let roll3Imag = downLoadedImages.find((ig) => ig.id === f.roll3ImageID)
                    let roll4Imag = downLoadedImages.find((ig) => ig.id === f.roll4ImageID)
                    let roll5Imag = downLoadedImages.find((ig) => ig.id === f.roll5ImageID)
                    let roll6Imag = downLoadedImages.find((ig) => ig.id === f.roll6ImageID)
                    let roll7Imag = downLoadedImages.find((ig) => ig.id === f.roll7ImageID)
                    let roll8Imag = downLoadedImages.find((ig) => ig.id === f.roll8ImageID)
                    let roll9Imag = downLoadedImages.find((ig) => ig.id === f.roll9ImageID)
                    let roll10Imag = downLoadedImages.find((ig) => ig.id === f.roll10ImageID)
                    let roll11Imag = downLoadedImages.find((ig) => ig.id === f.roll11ImageID)
            
                    let imagesFound = false; // Flag to track if at least one image is found
            
                    // Check if any of the images exist and assign data to the corresponding fields
                    if (roll1Imag) {
                        f.roll1ImageData = roll1Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll2Imag) {
                        f.roll2ImageData = roll2Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll3Imag) {
                        f.roll3ImageData = roll3Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll4Imag) {
                        f.roll4ImageData = roll4Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll5Imag) {
                        f.roll5ImageData = roll5Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll6Imag) {
                        f.roll6ImageData = roll6Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll7Imag) {
                        f.roll7ImageData = roll7Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll8Imag) {
                        f.roll8ImageData = roll8Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll9Imag) {
                        f.roll9ImageData = roll9Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll10Imag) {
                        f.roll10ImageData = roll10Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
                    if (roll11Imag) {
                        f.roll11ImageData = roll11Imag.data
                        tempCount += 1
                        imagesFound = true;
                    }
            
                    // Return the machine object if at least one image is found, otherwise exclude it
                    return imagesFound;  // Only keep the object if images are found
                });
            });
            
                        dJSON.BCSCheckListObject.BCSCheckListObjectChild.forEach(e => {
                e.Items.forEach(f => {
                    let imagobj = downLoadedImages.find((ig) => ig.id === f.imageID)
                    if (imagobj) {
                        tempCount += 1
                        f.imageData = imagobj.data
                      }
                });

            });
            dJSON.ThermalObject.ThermalObjectChild.forEach((e,i) => {
                e.HeaderObj.ThermalDataObj.forEach(f => {
                    let leftImage = downLoadedImages.find((ig) => ig.id === f.leftImageID)
                    let rightImage = downLoadedImages.find((ig) => ig.id === f.rightImageID)
                    const keyData = tempkeyItems.find(k => k.entityID === f.id && !k.deleted)
                    if (leftImage) {
                        tempCount += 1
                        f.ImageURL1 = leftImage.data
                    }
                    if (rightImage) {
                        tempCount += 1
                        f.ImageURL2 = rightImage.data
                    }
                    f.mappingKey = i
                    f.isKyeItem = keyData ? true : false
                });
            });      
                             
            dJSON.AllFormulas.AllFormulaObjectChild.forEach(e => {
                let imagobj = downLoadedImages.find((ig) => ig.id === e.Formula.signatureImageID)
                if (imagobj) {
                    imagobj.formulaID = e.Formula.id
                    tempCount += 1
                    e.Signature = imagobj 
                }
            });
            if (tempCount === downLoadedImages.length) {                
                const DataObj = { AllReportsData: JSON.stringify(dJSON) }
                
                let d= new Date(visit.startDate);
                let cd= new Date();
                const reportName = plant.name+"--Visit-"+  d.getDate().toString().padStart(2,"0") + '-'+ monthNames[Number((d.getMonth()).toString().padStart(2,"0"))] + "-"+d.getFullYear().toString().slice(-2) +'--report-generated-on'+"-" + cd.getDate().toString().padStart(2,"0") +"-"+ monthNames[Number((d.getMonth()).toString().padStart(2,"0"))] +"-"+ cd.getFullYear().toString().slice(-2);
                const printObj = {Loaded:true,Data:DataObj,ReportName:reportName,EmailSubject:'All visit reports '+plant?.name+' '+ visitDate};
                setfinalObj(printObj)
                localStorage.removeItem('IdList');
            }
        }
    }, [downLoadedImages]);

    useEffect(() => {
        if (callDownloadImage) {
            fetch('api/Attachments/details',
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + state.Auth.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(savedIdList)
                })
                .then(response => {
                    if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('data---------------', data);
                    setDownloadedImageData(data)
                })
                .catch(error => {
                    console.log('error---------------', error);
                    setShowErrAlert(true)
            });
        }
    }, [callDownloadImage]);
    const DowloadImage = () => {
        setcallDownloadImage(true)
    }
    function setDownloadedImageData(images) {
        Promise.all(ScaleImages(images,200,220))
            .then((values)=>{
                 setDownloadedImages(values)
            });
    }
    useEffect(() => {

        let data = { Temp: {
            first: "abc",
            Secpond: "abc",
            Third: "abc"
          }}
        
        if (DeJons !== null && isload && isLoadReport) {
            const DataObj = { AllReportsData: JSON.stringify(DeJons), AnotherObject: JSON.stringify(data) } 

            let d= new Date(visit.startDate);
                let cd= new Date();
                const reportName = plant.name + "--Visit-" + d.getDate().toString().padStart(2, "0") + '-' + monthNames[Number((d.getMonth()).toString().padStart(2, "0"))] + "-" + d.getFullYear().toString().slice(-2) + '--report-generated-on' + "-" + cd.getDate().toString().padStart(2, "0") + "-" + monthNames[Number((d.getMonth()).toString().padStart(2, "0"))] + "-" + cd.getFullYear().toString().slice(-2);
                const printObj = { Loaded: true, Data: DataObj, ReportName: reportName, EmailSubject: 'All visit reports ' + plant?.name + ' ' + visitDate };
                setfinalObj(printObj);
            localStorage.removeItem('IdList');
            setisload(false)
        }
    }, [DeJons, isLoadReport, isMetric]);

    useEffect(() => {   
        console.log('-----------finalObjfinalObj', DeJons);
        
    }, [finalObj]);

    const closeAlert = () => {
        navigate(`/VisitOverview/${vID}?${'printOption'}`)
        setShowSizeAlert(false)
    }
    const TryAgainCall = () => {
        navigate(`/VisitOverview/${vID}?${'printOption'}`)
        setShowSizeAlert(false)
    }

    if (ShowErrAlert) {
        return <div>
            <ErrorAlert ShowErrAlert={ShowErrAlert}   TryAgainCall={TryAgainCall} />
        </div>
    }
    if (!finalObj) {
        return <div>Loading...
            <SizeAlertDialog DownloadImgCount={DownloadImgCount} callDownloadImage={callDownloadImage} ShowSizeAlert={ShowSizeAlert} imageDownloadSize={imageDownloadSize}  DowloadImage={DowloadImage}   closeAlert={closeAlert} />
        </div>
    }


    // if (isLoading || !finalObj) {
    //     return <div>Loading...</div>;
    // }

   
    return (<>
        <ReportViewer type="AllReportsData" name="AllReportsData" printData={finalObj} onClose={handleClose} />
    </>);
}
export default VisitGlobalPrint;



export const SizeAlertDialog = props => {    
    return (
        <Dialog fullWidth maxWidth={"sm"} open={props.ShowSizeAlert} >
            <DialogContent>
                { props.callDownloadImage ?
                    <div  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div>
                        <div style={{ marginRight: '12px', height: '36px', textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                        <div  style={{ marginTop: '12px', textAlign: 'center' }}>
                            Please wait... downloading of images in progress
                        </div>
                        </div>
                    </div> : 
                    <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        Total {props.DownloadImgCount} 
                        {props.DownloadImgCount > 1 ? ' images ' : ' image '}
                        containing {props.imageDownloadSize} MB data will be downloaded to generate the report, do you want to continue? 


                        </Grid>
                        
                    </Grid>
                    <DialogActions>
                        <Button onClick={props.DowloadImage} >Yes</Button>
                        <Button onClick={props.closeAlert}>No</Button>
                    </DialogActions></>
                }
            
            </DialogContent>
        </Dialog >
    );
};

export const ErrorAlert = props => {    
    return (
        <Dialog fullWidth maxWidth={"sm"} open={props.ShowErrAlert} >
            <DialogContent>
                    <div  sx={{alignItems: 'center', justifyContent: 'center' }}>
                        <div  >
                        Something went wrong, Please try again.
                        </div>
                        <div style={{ textAlign: 'end' }}>

                        <Button  onClick={props.TryAgainCall}>OK</Button>
                        </div>
                    </div>
                    <></>
            </DialogContent>
        </Dialog >
    );
};