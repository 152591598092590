import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AddKeyItem } from '../../actions/KeyItemActions';
import { GetKeyItemByEntityID, DeleteKeyItem, UpdateKeyItem } from '../../actions/KeyItemActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import KeyItemEditDialog from './KeyItemEditDialog';
import { SelKeyItemByEntity } from '../../selectors/KeyItemSelectors';
import { v4 as uuidv4 } from 'uuid';
export const KeyItemCheckBox = props => {
    const constants = Constants;
    const dispatch = useDispatch();
    const [modalOpen, setModal] = useState(false);
    let checked = false;
    const keyItem = useSelector(state=>SelKeyItemByEntity(state,props.entity.id,props.field));
    if (keyItem)
        checked = true;
    let DefaultNote = '';
    if (props.defaultNote) {
        DefaultNote = props.defaultNote;
    }
    const editSubmit = (formVal) => {
        dispatch(UpdateKeyItem(formVal, keyItem));
        setModal(false);
    }
    const saveSubmit=(formVal)=>{
        dispatch(UpdateKeyItem(formVal, keyItem));

    }
    const location = useLocation();
    const handleChange = (e) => {
        if (e.target.checked != checked) {
            if (e.target.checked) {
                //Add Key Item
                addKeyItem();
            } else {
                if (props.edit) {
                     // console.log('update key item check---------------', props.setReference);
                    // console.log('update key item check', getReference());
                    // keyItem.reference = getReference()
                    setModal(true);
                } else {
                    dispatch(ConfirmAction("Do you want to delete this Key Item?", DeleteKeyItem(keyItem), null));
                }
            } 
        }
    }
    
    const addKeyItem = () => {
        const newKeyItem = {
            id : uuidv4(),
            visitID: props.visitID,
            entityID: props.entity.id,
            url: location.pathname,
            entityType: props.entityType,
            entityField: props.field,
            reference: getReference(),
            entityData: JSON.stringify(props.entity),
            notes: DefaultNote
        };
        dispatch(AddKeyItem(newKeyItem));
        if(props.edit)
            setModal(true);
    };

    const getReference = () => {
        let retVal=''
        if (props.setReference) {
            retVal = props.setReference(props.entity);
        } else if (props.entity.hasOwnProperty(props.field)) {
            retVal = props.entity[props.field];
        } 
        return retVal;
    };
    const onChangePriority = (value) => {
        keyItem.isPriority = value ? 'Yes' : '-'
    }
    return (
        <>
        <FormControlLabel
        control={
            <Checkbox
                checked={checked}
                onChange={handleChange}
                name={props.name}
                        color="primary"
                        tabIndex="-1"
                style={{zIndex: 1200}}
            />
        }
        label={'Key Item'}
            />
            <KeyItemEditDialog modalOpen={modalOpen} modalClose={() => { setModal(false); }}
                keyItem={keyItem} onChangePriority={onChangePriority} onSubmit={editSubmit} onSave={saveSubmit} />
            </>
    );
}