import Constants from '../../constants';
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { UnitView } from '../Formula/UnitViews';
import MUIDataTable from "mui-datatables";
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UpdateIngredientVerificationStep, CalculateAccumulatedWeight } from '../../actions/IngredientVerificationActions';
import produce from "immer";
import { KeyItemCheckBox } from '../KeyItems/KeyItemCheckBox';

import './CompactGrid.css';
const constants = Constants;
const IVStepsGrid = props => {
    const dispatch = useDispatch();
    const steps = useSelector(state => state.IngredientVerificationSteps
        .filter(s => s.starchIngredientVerificationID == props.ivID && !s.deleted)
        .sort((a, b) => a.stepLocation - b.stepLocation), shallowEqual);
    const [nextInput, setNext] = useState(null);
    const theme = useTheme();
    const fullButtons = useMediaQuery(theme.breakpoints.up('md'));
    
    console.log("IVSteps Render Props v3", props);
    const startWeight = props.startWeight
    useEffect(() => {
        console.log("Calculate Acc Weight Effect");
        dispatch(CalculateAccumulatedWeight(startWeight, props.ivID));
    }, [props.ivID])
    
    const checkVariance = (curObj, name, percent) => {
        console.log("Check Variance Called");
        const val = curObj[name];
        if (val == 0) { return null;}
        let base = 0;
        switch (name) {
            case "acutalWeight":
                base = curObj.value;
                break;
            case "mixerWeight":
                if (curObj.stepType == constants.Enums.StepType.Heat) { return null; }
                base = curObj.accumulatedWeight
                break;
            case "temperature":
                if (curObj.stepType !== constants.Enums.StepType.Heat) { return null; }
                base=curObj.value;
                break;
        }
        const variance = Math.abs(base - val);
        if (variance > (base * percent)) {
            return "Out of " + (percent * 100).toFixed(0) + "% tolerance";
        } else { return null;}
    };
    const InLineEditNum = React.memo(props => {
        const inputID = props.rowIndex + '_' + props.name;
        console.log("Render InLineEditNum", inputID);
        const curObj = steps[props.rowIndex];
        const [value, setValue] = useState(props.value);
        const [isError, setError] = useState(null);
        const getNextID = (curID) => {
            let nextVal = null;
            let row = Number(curID.substring(0, curID.indexOf('_')));
            if (curID.slice(-(12)) == 'acutalWeight')
                nextVal = row + '_mixerWeight';
            if (curID.slice(-(11)) == 'mixerWeight')
                nextVal = row + '_temperature';
            if (curID.slice(-(11)) == 'temperature')
                nextVal = (++row) + '_acutalWeight';
            return nextVal;
        };
        useEffect(() => {
            console.log("useEffect props.value InLineEditNum", props.value);
            setValue(props.value);
            let error = null;
            switch (curObj.stepType) {
                case constants.Enums.StepType.Water:
                    setError(checkVariance(curObj, props.name, .10));
                    break;
                case constants.Enums.StepType.Borax:
                case constants.Enums.StepType.Caustic_Percentage:               
                case constants.Enums.StepType.Starch:
                    setError(checkVariance(curObj, props.name, .03));
                    break;
                case constants.Enums.StepType.Liquid_Specialty:
                case constants.Enums.StepType.Heat:
                    setError(checkVariance(curObj, props.name, .08));
                    break;
                case constants.Enums.StepType.Liquid_Add:         
                    setError(checkVariance(curObj, props.name, .07));
                    break;
            }
        }, [props.value]);
        const isFocus = inputID === nextInput;
        const isHidden = (curObj.stepType === constants.Enums.StepType.Heat || curObj.stepType === constants.Enums.StepType.Mix) && props.name == 'acutalWeight';
        if (isHidden) {
            if (isFocus) {
                setNext(getNextID(inputID));
            }
            return null;
        }
        const handleOnChange = event => {
            console.log("Input onChange", event.target.value)
            setValue(event.target.value)
        };
        const handleKeyPress = event => {
            if (event.which == 9 || event.which == 13) {
                handleOnBlur(event);
            }
        }
        const selectAll = event => event.target.select()
        const input = <TextField value={value && value != null ? value : 0} id={inputID}
            onFocus={selectAll} autoFocus={isFocus} onKeyPress={handleKeyPress}
            error={isError ? true : false} helperText={isError} size="small"
            fullWidth variant="outlined" type="number" />;
        const handleOnBlur = event => {
            console.log("Input onBlur", event.target.value)
            const numVal = Number(event.target.value)
            if (!isNaN(numVal)) {
                
                    if (curObj[props.name] != numVal) {
                        const updObj = produce(curObj, draft => {
                            draft[props.name] = numVal;
                        });
                        console.log("IVSteps Props OnBlur", props);
                        setNext(getNextID(inputID));
                        dispatch(UpdateIngredientVerificationStep(updObj, curObj));
                        dispatch(CalculateAccumulatedWeight(startWeight, updObj.starchIngredientVerificationID));
                    }
                
            } else {
                setError("Invalid Number");
            }
        };
        return (
            <FormControlLabel
                value={props.value}
                control={input}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                label={''}
            />
        );
    });
    const columns = [{ name: "stepLocation", label: "#" }, { name: "step", label: "Step" },
        {
            name: "value", label: "Amount", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p>{value} <UnitView value={tableMeta.rowData[3]} /></p>;
                }
            }
        },
        {
            name: "unit", label: "Unit", options: {
                display:false
            }
        },
        //{ name: "mixTime", label: "MixTime" }, { name: "cost", label: "Cost" },
        {
            name: "acutalWeight", label: "ActWt", options: {
                customBodyRender: (value, tableMeta, updateValue) => {

                    return <InLineEditNum value={value}  rowIndex={tableMeta.rowIndex} name={'acutalWeight'} />
                }
            }
            
        },
        { name: "accumulatedWeight", label: "AccWt" },
        {
            name: "mixerWeight", label: "MixWt", options: {
                customBodyRender: (value, tableMeta, updateValue) => <InLineEditNum value={value}  rowIndex={tableMeta.rowIndex} name={'mixerWeight'} />
            }
        },
        {
            name: "temperature", label: "Temp", options: {
                customBodyRender: (value, tableMeta, updateValue) => <InLineEditNum value={value} rowIndex={tableMeta.rowIndex} name={'temperature'} />
            }
        },
        {
            name: "buttons", label: " ", options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    //Get IVStep
                    const curObj = steps[tableMeta.rowIndex];
                    return <IVGridButtons fullButtons={fullButtons} curObj={curObj} visitID={props.visitID} />
                }
            }
        }
    ];
    const options = {
        filter: false,
        fixedHeader: true,
        filterType: "dropdown",
        responsive: "standard",
        selectableRows: "none", searchOpen: false, sort: false, viewColumns: false, search: false,
        pagination: false,print:false,download:false
        /*tableBodyHeight: (windowSize.height - 250) + 'px'*/
       
    };
    return <MUIDataTable
            title={"Steps"}
            data={steps}
            columns={columns}
            options={options}
           

    />;
};


const IVGridButtons = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    if (props.fullButtons) {
        return (<div style={{ width: '200px' }} >
            <KeyItemCheckBox entity={props.curObj} field={'comment'} visitID={props.visitID}
                setReference={(entity) => { return entity.stepLocation + '-' + entity.step }}
                entityType={constants.Surveys.IngredientVerification} edit={true}
            />
           
        </div>);
    }
    
    return <>
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <KeyboardArrowDownIcon />
        </IconButton>
        <Menu
            variant="menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
           
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem>
                <KeyItemCheckBox entity={props.curObj} field={'comment'} visitID={props.visitID}
                    setReference={(entity) => { return entity.stepLocation + '-' + entity.step }}
                    entityType={constants.Surveys.IngredientVerification} edit={true}
                />
            </MenuItem>
           
        </Menu>
    </>;
};

export default IVStepsGrid;