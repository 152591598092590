import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams } from 'react-router-dom';
import ReportViewer from '../Report/ReportViewer';
import { GetIngredientVerificationByID, GetIVStepsByParentID } from '../../selectors/IngredientVerificationSelectors';
import { KeyItemsFilteredByArray } from '../../selectors/KeyItemSelectors';
import { PopulateFormulaStepsStrings, SelFormulaByID } from '../../selectors/FormulaSelectors';
import { SelVisitByID,SelInventoryByVisitID,SelMeasuresByVisitID,FormatLocation,SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
const IVPrint = props => {
    // const steps = useSelector(state => state.IngredientVerificationSteps
    //     , shallowEqual);
    const navigate = useNavigate();
    const params=useParams();
    const ivID = params.VerificationID;
    const printData=useIVPrintData(ivID);
    const handleClose = () => {
        navigate(-1);
    }
    console.log("IVPrint Render");
    return <ReportViewer type="IngredientVerification" name="IngredientVerification" printData={printData} 
       onClose={handleClose}  />;
    
}
export default IVPrint;
export const useIVPrintData=(ivID)=>{
    const UserData = useReportUser();
    const IV = useSelector(s=>GetIngredientVerificationByID(s,ivID));
    const formula =useSelector(s=>SelFormulaByID(s,IV?.formulaID));
    const visitID=IV?.visitID
    const plant = usePlantDataFromVisitID(visitID);
    
    const visit = useSelector(s=>SelVisitByID(s,visitID));
    const reportName = useReportName("IngredientVerification",plant?.name,visit);
    const inventory = useSelector(s=>SelInventoryByVisitID(s,visitID));
    const measurements = useSelector(s=>SelMeasuresByVisitID(s,visitID)).map(m=>{
        return {...m, locationName:FormatLocation(m.location)};
    });
    const steps = PopulateFormulaStepsStrings(useSelector(s=>GetIVStepsByParentID(s,ivID)));
    const skItems = useSelector(s=>SelSKItemsByVisitID(s,visitID));
    const kiArray=[...steps,...skItems];
    const keyItems = useSelector(s=>KeyItemsFilteredByArray(s,kiArray));
    const dJSON={IngredientVerification:IV, IVSteps:steps, User:UserData, 
        Plant:plant, Visit:visit, KeyItems:keyItems,Inventory:inventory,
        Measurements:measurements,SKItems:skItems,Formula:formula};
    const Data ={IVData:JSON.stringify(dJSON)};
    let visitDate=null;
    if(visit){
        const d=new Date(visit.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    return {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'IngredientVerification '+formula?.name+' '+plant?.name+' '+visitDate};
};