import produce from "immer";
export const UpdateArray = (updObj, updArray) => {
    if (!updObj) return updArray;

    // Ensure no duplicates exist in the state based on id
    const exists = updArray.some(curObj => curObj.id === updObj.id);
    if (exists) {
        return updArray.map(curObj => curObj.id === updObj.id ? updObj : curObj);
    } else {
        return [...updArray, updObj];
    }
};

export const ChangeOrder = (updId, updArray, isUp, addFilter,field='order') => {
    const change = updArray.find(m => m.id == updId);
    let newOrder = -1;
    if (isUp) {
        newOrder = change[field] + 1;
    } else {
        newOrder = change[field] - 1;
    }
    const other = updArray.find(m => m[field] === newOrder && addFilter(change, m) && !m.deleted);
    if (other && other.id !== change.id) {
        const newChange = produce(change, draft => { draft[field] = newOrder });
        const newOther = produce(other, draft => { draft[field] = change[field] });
        return [...updArray.filter(m => m.id !== newChange.id && m.id !== newOther.id), newChange, newOther];
    } else {
        return updArray;
    }
};
export const UpdateArrayRange=(updArray, curArray) => {
    const retArray = updArray;
    curArray
        .filter(i => {
            return !updArray.reduce((acc, v) => {
                if (acc) {
                    return acc;
                } else {
                    return v.id == i.id
                }
            }
                , false);
        })
        .forEach(a => {
            retArray.push(a);
        });
    return retArray;
};